

const toLowercaseAndCapitalize=(text)=> {
    return text
      .toLowerCase() // Convertir todo el texto a minúsculas
      .split(' ')    // Dividir el texto en palabras
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar cada palabra
      .join(' ');    // Unir las palabras de nuevo
  }

  export {toLowercaseAndCapitalize}