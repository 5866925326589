import React, { useContext, useState } from 'react'
import Paragraph from '../../../../atoms/paragraph'
import GroupRadio from '../../../../molecules/group-radio'
import RadioButton from '../../../../atoms/radio-button'
import { ReactComponent as RecognitionMedal } from "../../../../../resources/images/just-in-time-coordinator.svg";
import { ReactComponent as JustInTimeMedal } from "../../../../../resources/images/just-in-time-medal.svg";
import Button from '../../../../atoms/button';
import MedalSwitch from '../../../../molecules/medal-switch';
import { UserContext } from '../../../../../middleware/providers/user-context';
import Modal from '../../../../atoms/modal';
import { ROLES } from '../../../../../middleware/utils/constants';
import { assignMedal } from '../../../../../middleware/services/common';
import { useParams } from 'react-router-dom';
const ViewTeamMedalJustinTime = ({ setStep, step,hostWinners }) => {
    console.log(hostWinners)
    //const [learningSkill, setRooms] = useState(null)
    const { user} = useContext(UserContext);
    const [rooms, setRooms] = useState([]);
    const [medals, setMedals] = useState([]);
    
    const [modalConfirmation, setModalConfirmation] = useState({
        isOpen: false,
        value: ''
    });
    return (
        <>
            <span className="title-span"><b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo</b></span>
            <div className="medal-text">
                <Paragraph size="s" align="center" >
                    Para ganar esta medalla de equipo, los dos Anfitriones deben completar el entrenamiento máximo 1 hora con 45 minutos después de la hora de inicio del EPA.
                </Paragraph>
            </div>
            <br/>
            <div className="tutorial-time-management__info">
                <br />
                <span className="just-in-time-coordinator">
                    <RecognitionMedal className="candidate" width="33.75" height="29.9" />
                </span>


                <Paragraph size="s" align="center" >
                    Presiona SÍ, para otorgar la medalla en equipo de Justo a Tiempo equipo; y NO, en caso contrario.
                    <br/>
                    <br/>
                </Paragraph>
         
            </div>
            <br/>
            {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled={index > 0} onClick={(value) => {
                        setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        setModalConfirmation({
                            ...modalConfirmation,
                            value: value === 'No' ? value.toUpperCase() : value,
                        });
                    }} />)}
            <Button onClick={() => setStep(step + 1)} className="coorbutton coordinator button--large button--default" >Continuar</Button>
            
        </>
    )
}


export default ViewTeamMedalJustinTime