export const simulationData=[
    {
        "_id": "6705de42e004e059b1ac289a",
        "idCoordinator": "66c29b71870f00e1df945973",
        "__v": 0,
        "createdAt": "2024-10-09T01:37:06.061Z",
        "idDirector": "667f444fc6e8693222cb04a5",
        "nameCoordinator": "Henry Lender Ortega Alzate",
        "schedule": [
            {
                "day": "Día de Inducción",
                "date": "29/11/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día de Reglas",
                "date": "22/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día 1",
                "date": "23/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día 2",
                "date": "24/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día 3",
                "date": "25/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día de Filtro",
                "date": "26/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "Día de Alineamiento",
                "date": "27/12/2024",
                "hour": "5:22: AM"
            },
            {
                "day": "EPA",
                "date": "28/12/2024",
                "hour": "5:22: AM"
            }
        ],
        "updatedAt": "2024-11-28T19:47:04.710Z"
    },
    {
        "_id": "6705de4de004e059b1ac296a",
        "idCoordinator": "66c548e7b263ad70f58f945c",
        "__v": 0,
        "createdAt": "2024-10-09T01:37:17.874Z",
        "idDirector": "667f444fc6e8693222cb04a5",
        "nameCoordinator": "PATRICIA ISAURA JARAMILLO CACERES",
        "schedule": [
            {
                "day": "Día de Inducción",
                "date": "27/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día de Reglas",
                "date": "7/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día 1",
                "date": "8/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día 2",
                "date": "9/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día 3",
                "date": "10/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día de Filtro",
                "date": "11/11/2024",
                "hour": "19:23"
            },
            {
                "day": "Día de Alineamiento",
                "date": "12/11/2024",
                "hour": "19:23"
            },
            {
                "day": "EPA",
                "date": "13/11/2024",
                "hour": "19:23"
            }
        ],
        "updatedAt": "2024-11-26T23:28:29.873Z"
    }
]


export const  ScheduleTemp=[
            {
                "day": "Día de Inducción",
                "date": "",
                "hour": ""
            },
            {
                "day": "Día de Reglas",
                "date": "",
                "hour": ""
            },
            {
                "day":"Día 1",
                "date": "",
                "hour": ""
            },
            {
                "day": "Día 2",
                "date": "",
                "hour": ""
            },
            {
                "day": "Día 3",
                "date": "",
                "hour": ""
            },
            {
                "day": "Día de Filtro",
                "date": "",
                "hour": ""
            },
            {
                "day": "Día de Alineamiento",
                "date": "",
                "hour": ""
            },
            {
                "day": "EPA",
                "date": "",
                "hour": ""
            }
        ];