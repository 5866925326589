import Paragraph from '../../../../atoms/paragraph'
import { API, ROLES } from '../../../../../middleware/utils/constants';
import Select from '../../../../atoms/select';
import { useContext, useEffect, useState } from 'react';
import Button from '../../../../atoms/button';
import axios from 'axios';
import { UserContext } from '../../../../../middleware/providers/user-context';

const circle = {
    host: '⚪',
    'new host': '⚪',
    coordinator: '🔴',
    'new coordinator': '🔴',
    director: '🟡',
    captain: '🔵',
};

const NowYourTurnSelectRoomA = ({ setStep, step, applicantsAndHosts }) => {
   
    const { user } = useContext(UserContext)
    const [selectedCandidate, setSelectedCandidate] = useState([]);
    const [state, setState] = useState({
        selected: [{ id: '',index:0, status: true }],
      
    });

    const [shortlistedZero, setShortlistedZero] = useState(null);
    const [shortlistedOne, setShortlistedOne] = useState(null);
    const [flagNext, setflagNext] = useState(0);
    useEffect(() => {
        applicantsAndHosts.map((item, index) => {
           
            axios.get(`${API.SHORTLISTED_APPLICANTS.url}/${item?._id}?action=SHORTLISTED_APPLICANTS`).then(response => {
                if (index === 0) {
                    setShortlistedZero(response.data.data)
                }
                if (index === 1) {
                    setShortlistedOne(response.data.data)
                }
            })
        })
    }, [applicantsAndHosts]);

    const handleSelect = (idCandidate,pos) => {
     
        setState((prevState) => {
            let newSelected = [...prevState.selected];
            // Si la opción seleccionada es vacía, eliminarla del estado si existe
            if (idCandidate === "" ||idCandidate === null) {
              newSelected = newSelected.filter((item) => item.index !==pos && item.id!== idCandidate);
            } else {
              // Si existe, actualizamos el valor, si no, añadimos uno nuevo
              const existingIndex = newSelected.findIndex((item) => item.id === idCandidate);
              if (existingIndex !== -1) {
                newSelected[existingIndex] = { id: idCandidate,index:pos, status: true };
              } else {
                newSelected.push({ id: idCandidate,index:pos, status: true });
              }
            }

            return { ...prevState, selected: newSelected };
          });
 
        if (idCandidate?.length > 0) {
            setflagNext(flagNext + 1);
        } else {
            setflagNext(flagNext - 1);
        }
    };

    const sendSelection = (e) => {
        e.preventDefault()

        const data = {
            id: "",
            selected: state.selected.filter(candidate => candidate.id !== ""),
            action: 'SELECT_FINAL_EPA_CANDIDATES',
        };
        axios.post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data).then((response) => {
            if (response.status === 201) {
         
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
       
    };


    return (
        <>
            <span className="title">¡Ahora es tu turno de seleccionar!</span>

            <Paragraph size="s" align="center" className="p-room-p-0">
                <br/>
                ¡Coordinador(a)! Si la decisión de quienes son los dos Aspirantes seleccionados de cada Anfitrión, <br/>dependiera solo de ti,

                <br/>¿a quiénes seleccionarías?
                <br/>
                <br/>
            </Paragraph>
            <form onSubmit={sendSelection}>
                {

                    applicantsAndHosts?.map((generation, index) => <div className="generation-map">
                        <Paragraph size="xs" align="center" >
                            <div className={`info-title `} >
                                <span className="role"> {ROLES[generation.role]?.circle} </span>
                                <span className="title-name"><b>{generation.name}:</b></span>
                            </div>
                        </Paragraph>
                        <div className="epa-requirements__status">
                            {
                                index === 0 && (<>
                                    {
                                        <>
                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value,1)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedZero?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value,2)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedZero?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </>

                                    }
                                </>)
                            }
                            {
                                index === 1 && (<>
                                    {
                                         <>
                                         <div className={`medal-switch select-candidate`}>
                                             <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value,3)}>
                                                 <option value="">Selecciona tu Aspirantes</option>
                                                 {
                                                     shortlistedOne?.map((candidate) => (
                                                         <option value={candidate?.idUser} >{candidate.name}</option>
                                                     ))
                                                 }
                                             </Select>
                                         </div>
                                         <div className={`medal-switch select-candidate`}>
                                             <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value,4)}>
                                                 <option value="">Selecciona tu Aspirantes</option>
                                                 {
                                                     shortlistedOne?.map((candidate) => (
                                                         <option value={candidate?.idUser} >{candidate.name}</option>
                                                     ))
                                                 }
                                             </Select>
                                         </div>
                                     </>
                                    }
                                </>)
                            }
                        </div>

                    </div>)}


                {/* <Button onClick={() => setStep(step + 1)} className="margin-top" disabled={flagNext===0?'disabled':''}>Continuar</Button>
       */}
                <Button  className="margin-left" disabled={flagNext === 0 ? 'disabled' : ''}>Continuar</Button>
            </form>
          
        </>
    )
}

export default NowYourTurnSelectRoomA
