import React from 'react'
import Paragraph from '../../../../atoms/paragraph'
import Button from '../../../../atoms/button'
import ClapHand from "../../../../../resources/images/clap-hand-coordinator.png";
export const EndEpa = ({finishChallenge,setStep, step}) => {
    return (
        <>
            <Paragraph size="s" align="center">
                <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center" className="p-small-1">
                Has completado el Desafío de la <b>Sala de Admisiones.</b>
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <div className="tutorial-time-management__end">
            <Paragraph size="s" align="center"  className="description-text">
            Ahora que ya terminaste los 3  Desafíos de las  Salas del EPA y conoces quienes cumplen los requisitos para quedar seleccionados     
            </Paragraph>
            <Paragraph size="s" align="center"  className="description-text">
           
                Ya puedes pasar al Cambio de Rol  donde se definirá quienes quedan  seleccionados y quienes  quedan en Lista de Espera .
                <br />
                <br/>
                <b>👋🏻 Ya nos vemos.</b>
                
                <br/>
                <br/>
            </Paragraph>
            </div>
            <Button  onClick={() => finishChallenge(null)} className="end button--large button--default">Finalizar</Button>
        </>
    )
}
