import React, { useContext, useState } from "react";
import Button from "../../../../atoms/button";
//import './index.scss';
import Checkbox from "../../../../atoms/checkbox";
import { UserContext } from "../../../../../middleware/providers/user-context";
import { QueriesListReidualDatesById } from "../../../../../middleware/services/useQueryListResidualDatesServices";
import axios from "axios";
import { API } from "../../../../../middleware/utils/constants";
import {
  formatearDate,
  getHoraAmAndPm,
  reverseDate,
  reverseFomatDate,
} from "../../../../../middleware/utils/formatDate";
import CustomDatePicker from "../../../../molecules/date-picker";
export const PossibleAvailableDates = ({
  setStep,
  step,

  setEditDate,
  idCoordinator,
  setEpaDateCoordinatingSchedulesById,
}) => {
  const { user } = useContext(UserContext);

  const [activeBtn, setActiveBtn] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [dateEdit, setDateEdit] = useState(null);
  const [timeEdit, setTimeEdit] = useState(new Date());
  const [timeSelected, setTimeSelected] = useState(false);
  const { data } = QueriesListReidualDatesById(user?._id);

  const [selectedDates, setSelectedDates] = useState({
    date: "",
    hour: "",
    status: false,
  });

  const handleDateSelection = (isChecked, row) => {
    setSelectedDates((prevState) => ({
      data: [
        {
          status: true,
          date: row.date.reversed(),
          hour: row.hour,
        },
      ],
    }));
  };

  const handleCreateDate = (isChecked, row) => {
    data.forEach((item) => {
      item.residualDates.forEach((residualDate) => {
        if (residualDate.date === row.date) {
          residualDate.status = true;
          setChecked(true);
        }
      });
    });
    if (isChecked) {
      setSelectedDates((prevState) => ({
        data: [
          {
            date: row.date,
            hour: row.hour,
            status: true,
          },
        ],
      }));
    } else {
    }
  };
  const handleSend = async () => {
    setEpaDateCoordinatingSchedulesById([]);

    setEditDate([
      {
        date: reverseFomatDate(selectedDates?.date),
        hour: getHoraAmAndPm(selectedDates?.hour),
        idCoordinator: idCoordinator,
      },
    ]);
    let data = {
      data: [
        {
          date: reverseFomatDate(selectedDates?.date),
          hour: getHoraAmAndPm(selectedDates?.hour),
          status: true,
        },
      ],
    };

    axios
      .put(
        `${API.SAVE_SELECTED_DATES_FOR_EPA.url}/${idCoordinator}?action=save-selected-dates-for-epa`,
        data
      )
      .then((response) => {
        if (response.status === 200) {
          axios
            .get(
              `${API.EPA_DATE_COORDINATING_SCHEDULES_BY_ID.url}/${user._id}?action=coordinating-schedules`
            )
            .then((response) => {
              if (response.status === 200) {
                setEpaDateCoordinatingSchedulesById(response.data.data);
                setActiveBtn(false);
                setDateEdit(null);
                setDateSelected(false);
                setTimeSelected(false);
                setStep(step - 3);
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDateChangeEdit = (newTime) => {
    setDateEdit(newTime);

    setIsDatePickerOpen(false);
    setDateSelected(true);
    setSelectedDates({
      date: newTime,
      hour: selectedDates.hour,
    });
  };

  const handleTimeChangeEdit = (newTime) => {
    setTimeEdit(newTime);
    setIsTimePickerOpen(false);
    setTimeSelected(true);
    setSelectedDates({
      date: selectedDates.date,
      hour: newTime,
    });
  };
  const getAdjustedDate = (baseDate, offsetDays) => {
    const date = baseDate || new Date();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + offsetDays
    );
  };
  return (
    <>
      <div className="event-container">
        <span className="title">
          Elige una de las posibles fechas disponibles
        </span>
        {data &&
          data?.map((item) => (
            <div key={item._id}>
              {item.residualDates?.map((residualDate, index) => (
                <div className="event-possible">
                  <Checkbox
                    className="event-possible-checkbock"
                    name="hold"
                    checked={residualDate.status}
                    onChange={(e) => {
                      handleCreateDate(e.target.checked, residualDate);
                      handleDateSelection(e.target.checked, residualDate);
                    }}
                    value={residualDate.date}
                  />
                  <span className="event-possible-title">
                    {residualDate.date}
                  </span>
                  <span className="event-possible-hour">
                    {residualDate.hour}
                  </span>
                </div>
              ))}
              <span className="event-possible-hour">
                No elegida por {item.name}
              </span>
            </div>
          ))}
        <div className="event-possible">
          <Checkbox
            name="hold"
            className="event-possible-checkbock"
            checked={activeBtn}
            onChange={(e) => setActiveBtn(e.target.checked)}
          />
          <div
            className="event-possible-title"
            onClick={(e) => setIsDatePickerOpen(true)}
          >
            <span className="checkbox-table__item__start-time">
              {dateSelected ? formatearDate(dateEdit) : "Crear fecha"}
            </span>
          </div>
          <span
            className="event-possible-hour"
            onClick={(e) => setIsTimePickerOpen(true)}
          >
            {timeSelected ? getHoraAmAndPm(timeEdit) : "0:00__"}
          </span>
          <CustomDatePicker
            isOpen={isDatePickerOpen}
            onSelect={handleDateChangeEdit}
            time={dateEdit || new Date()}
            min={getAdjustedDate(dateEdit, 8)}
            max={getAdjustedDate(dateEdit, 15)}
            role="coordinator"
            onCancel={() => setIsDatePickerOpen(false)}
          />

          <CustomDatePicker
            isOpen={isTimePickerOpen}
            onSelect={(time) => {
              const roundedMinutes = time.getMinutes() < 30 ? 0 : 30;
              const adjustedTime = new Date(time.setMinutes(roundedMinutes));
              handleTimeChangeEdit(adjustedTime); // Asegura que el valor sea válido
            }}
            time={timeEdit}
            role="coordinator"
            onCancel={() => setIsTimePickerOpen(false)}
            isTimePicker
            showTimeSelect
            timeIntervals={30} // Muestra solo opciones cada 30 minutos (00 y 30)
            timeFormat="HH:mm"
          />
        </div>
        <span className="event-possible-span">
          {" "}
          Elige una fecha del calendario{" "}
        </span>
      </div>
      {checked ? (
        <Button onClick={handleSend} disabled={!checked}>
          Aceptar Fecha
        </Button>
      ) : (
        <Button onClick={handleSend} disabled={!activeBtn}>
          Aceptar Fecha
        </Button>
      )}
    </>
  );
};
