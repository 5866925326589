import { useContext, useEffect, useState } from "react";

import ClapHand from "../../../resources/images/clap-hand-coordinator.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
import CoordinatorTools from "../../../resources/images/coordinator-tools.png";

import Book from "../../../resources/images/book.png";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";

import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";

import Modal from "../../atoms/modal";


import CheckList from "../../../resources/images/check-icon.png";
import Union from "../../../resources/images/union.png";
import { Quote } from "../../molecules/quote";
import { CardCopyKey } from "../../molecules/card-copy-key";
import MenuUserInfo from "../../molecules/menu-user-info";
import { TextFieldList } from "../../molecules/text-field-list";
import TextField from "../../atoms/text-field";

import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import { GridGiftSwitch } from "../../molecules/grid-gift-switch";

import GiftSwitchOn from "../../molecules/gift-switchOn";

let titleRoleCoordinatorDiretor = [
  "Lista de Espera Coordinador(a)  🔴 (Tú)",
  "Lista de Espera  Director(a) 🟡",
];

const CoordinatorRulesDay = ({ step, setStep, finishChallenge }) => {
  const { challengeName, idChallenge } = useParams();
  const { user, setMedalModal } = useContext(UserContext);

  const [listHosts, setListHosts] = useState();

  const [currentHostIndex, setCurrentHostIndex] = useState(0);

  const [open, setOpen] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const [director, setDirector] = useState([]);

  useEffect(() => {
    setCurrentHostIndex(0);

    const data = {
      params: {
        day: "rules day",
      },
    };

    axios
      .get(
        `${API.CHALLENGES_WITH_MEDALS_BE_ASSIGNED.url}/${user?._id}?action=CHALLENGES_WITH_MEDALS_BE_ASSIGNED`,
        data
      )
      .then((response) => {
        if (response.status === 200) {
          const challenge = response.data.data;
          if (challenge[0].wildcard) {
            setMedalModal("continue");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    fetchData();
  }, []);
  const fetchData = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setListHosts(response.data.data);
      })
      .catch((err) => console.log("Error" + err));
  };


  const handleNextHost = () => {
    if (currentHostIndex < listHosts.length - 1) {
      setCurrentHostIndex(currentHostIndex + 1);
    }
  };

  const [hostByPersonalReferences, setHostByPersonalReferences] = useState({
    selectedIdHost: [],
  });
  const [selectedIdHost, setSelectedIdHost] = useState("");
  const handleModalListClick = (idHost) => {
    setSelectedIdHost(idHost);
    setOpen(true);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const handleNavigation = (plus) => {
    const navigation = currentSlide + plus;
    if (navigation < 2 && navigation >= 0) {
      setCurrentSlide(navigation);
    }
  };

  const selectedCandidate = (id) => {
    setSelectedCandidates((prev) => [...prev, id]);
  };
  const [hostActive, setHostActive] = useState([]);
  const putAddToWhatSapp = (idHost, status, e) => {
    let dataUpdate = {
      status: status,
      property: "addedToWhatsappGroup",
    };

    axios
      .put(
        `${API.ADD_TO_WHATSAPP_GROUP.url}/${idHost}?action=update-property-host`,
        dataUpdate
      )
      .then((response) => {
        if (response.status === 200) {
          setHostActive((prev) => {
            if (status) {
              if (!prev.includes(idHost)) {
                return [...prev, idHost];
              }
            } else {
              return prev.filter((host) => host !== idHost);
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInformation();
  }, []);
  const getInformation = async () => {
    try {
      const response = await axios.get(
        `${API.GET_INFORMATION.url}/${user?.createByHost}?action=GET_INFORMATION`
      );
      if (response.status === 200) {
        setDirector(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
const  [countSave,setCountSave]=useState(0)
  const savePersonalReference = () => {
    Object.entries(hostByPersonalReferences).forEach(([hostId, array]) => {
      array.forEach((personal) => {
        let data = {
          references: [
            {
              name: personal.name,
              email: "string",
              suggested: true,
            },
          ],
        };
        axios
          .put(
            `${API.SAVE_PERSONAL_REFERENCES.url}/${personal.idHost}/${idChallenge}`,
            data
          )
          .then((response) => {
            setStep(step + 1)
          })
          .catch((error) => {
            console.log(error);
          });
    
        setCountSave(prevCount => prevCount + 1); // Incremento correcto
      });
    });
    
   
  };

  console.log("user.personalReferences")
  console.log(user.personalReferences)
  console.log("selectedIdHost")
  console.log(selectedIdHost)
  console.log(listHosts)
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              Arte
            </Paragraph>

            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="s">
                  "El arte del tercer nivel de influencia (LIDERAZGO) es
                  entender que los resultados provienen de un esfuerzo en
                  equipo. Entender que las personas deben estar de acuerdo con
                  su liderazgo y aceptarlo antes que cualquier cosa. Las
                  personas en este nivel de influencia, entienden la importancia
                  de encontrar y desarrollar buenas personas; también que el
                  liderazgo es la demarcación base, pues todo comienza con el
                  liderazgo y también termina con el liderazgo. Saben también el
                  impacto que tienen sus acciones sobre la organización,
                  entienden que hay precios a pagar y aprenden a encontrar
                  derrotas en cada victoria para mejorar y victorias en cada
                  derrota para reconocer.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              className="rules-day-container-button"
              style={{
                position: "relative",
                marginTop: "96px;",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="rules-day-container"
          >
            <Paragraph align="center" size="s">
              ¡Genial!
              <br />
              <br />
              Has completado el Desafío A.
              <img
                src={GoodHandCoordinator}
                alt="good-hand-coordinator"
                style={{ marginTop: "10px" }}
              />
            </Paragraph>
            <CardCopyKey
              phrase="Pensamientos Coordinador"
              nameChallenge="Desafío A"
            />
            <Button
              onClick={() => {
                setMedalModal("");
                finishChallenge(null);
              }}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="rules-day-container"
          >
            <Paragraph align="center" size="s">
              Ciencia
            </Paragraph>

            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx">
                  “La ciencia de las personas en este nivel de influencia es
                  moldear el camino. Inspirar a las personas a tener un buen
                  desempeño, capacitarlos y prepararlos. Un líder sirve a su
                  gente, organiza actividades, mide resultados y resuelve
                  problemas enfocándose principalmente en las soluciones. Si nos
                  llega una situación a resolver, nos enfocamos en cómo podemos
                  resolverla, no en por qué pasó o como pudo haber sido; sino en
                  cómo soluciono ahora lo que está ocurriendo. También, tienen
                  una excelente comunicación con su equipo, tanto de mentores
                  como de personas a su cargo.”
                </Paragraph>
              </div>
            </div>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo</b>
              </Paragraph>
            </div>

            <Button
              onClick={() => setStep(step + 1)}
              className="rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>Actividad Lista de Espera Convocatoria</b>
            </Paragraph>
            <img src={Union} alt="union" />
            <Paragraph size="sx" align="center">
              Verifica en la pestaña de <b>Mi Desempeño</b> si <br /> ambos ya
              lograron la Meta 1.
            </Paragraph>
            <Paragraph size="sx" align="center">
              Después de haber aplicado el Plan B, para quién <br />
              no lo haya logrado y tenga al menos 4 <br />
              agendados: Se activará la{" "}
              <b>
                lista de espera <br /> convocatoria.{" "}
              </b>
            </Paragraph>
            <Paragraph size="sx" align="center">
              Podrá usar tu lista de espera solo si tu lista no tiene personas
              en espera. Si es el caso contrario, podrás acudir a las de tu
              Director(a) y/o la de tu Capitán(a).
            </Paragraph>
            <Button
              className="rules-day-container-button"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            {listHosts && listHosts.length > 0 && (
              <>
                <Card
                  type="quinary"
                  outline
                  className="rules-day-container-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "343px",
                    paddingLeft: "13px",
                    marginBottom: "16px",
                  }}
                >
                  <MenuUserInfo
                    name={toLowercaseAndCapitalize(
                      listHosts[currentHostIndex].name
                    )}
                    role="host"
                    nameRole="Anfitrión"
                    range={7}
                    orientation="row"
                    size="small"
                  />
                  <Paragraph align="center" size="sx">
                    Requiere de{" "}
                    <strong style={{ color: "#4DFFDD" }}>
                      {listHosts.length}
                    </strong>{" "}
                    personas de Lista de Espera.
                  </Paragraph>
                </Card>
                <Paragraph
                  size="sx"
                  align="justify"
                  style={{
                    maxWidth: "320px",
                  }}
                >
                  A continuación, elige las personas que usarás de tu Lista de
                  Espera para ayudar a tu Anfitrión(a):
                </Paragraph>

                {currentHostIndex === 0 ? (
                  <>
                    <TextFieldList>
                      <TextField
                        disabled={true}
                        placeholder="Selecciona un Aspirante"
                        value={
                          hostByPersonalReferences?.selectedIdHost?.[0]
                            ?.idHost === listHosts[currentHostIndex]?._id
                            ? hostByPersonalReferences?.selectedIdHost?.[0]
                                ?.name || ""
                            : ""
                        }
                        rightIcon={{
                          icon: "icon-add",
                          size: 24,
                          color: "#0063DD",
                          onClick: () =>
                            handleModalListClick(
                              listHosts[currentHostIndex]?._id
                            ),
                        }}
                      />
                      <TextField
                        disabled={true}
                        placeholder="Selecciona un Aspirante"
                        value={
                          hostByPersonalReferences?.selectedIdHost?.[1]
                            ?.idHost === listHosts[currentHostIndex]?._id
                            ? hostByPersonalReferences?.selectedIdHost?.[1]
                                ?.name || ""
                            : ""
                        }
                        rightIcon={{
                          icon: "icon-add",
                          size: 24,
                          color: "#0063DD",
                          onClick: () =>
                            handleModalListClick(
                              listHosts[currentHostIndex]?._id
                            ),
                        }}
                      />
                    </TextFieldList>
                  </>
                ) : (
                  <>
                    <TextFieldList>
                      <TextField
                        disabled={true}
                        placeholder="Selecciona un Aspirante"
                        value={
                          hostByPersonalReferences?.selectedIdHost?.[2]
                            ?.idHost === listHosts[currentHostIndex]?._id
                            ? hostByPersonalReferences?.selectedIdHost?.[2]
                                ?.name || ""
                            : ""
                        }
                        rightIcon={{
                          icon: "icon-add",
                          size: 24,
                          color: "#0063DD",
                          onClick: () =>
                            handleModalListClick(
                              listHosts[currentHostIndex]?._id
                            ),
                        }}
                      />
                      <TextField
                        disabled={true}
                        placeholder="Selecciona un Aspirante"
                        value={
                          hostByPersonalReferences?.selectedIdHost?.[3]
                            ?.idHost === listHosts[currentHostIndex]?._id
                            ? hostByPersonalReferences?.selectedIdHost?.[3]
                                ?.name || ""
                            : ""
                        }
                        rightIcon={{
                          icon: "icon-add",
                          size: 24,
                          color: "#0063DD",
                          onClick: () =>
                            handleModalListClick(
                              listHosts[currentHostIndex]?._id
                            ),
                        }}
                      />
                    </TextFieldList>
                  </>
                )}
                <Button
                  onClick={() => {
                    if (currentHostIndex === 1) {
                      savePersonalReference();
                    } else {
                      handleNextHost();
                    }
                  }}
                  className="rules-day-container-button"
                >
                  Aceptar
                </Button>
              </>
            )}
            <Modal
              isOpen={open}
              onClose={() => setOpen(false)}
              className="list-modal"
            >
              <span
                className="close icon-close"
                onClick={() => setOpen(false)}
                onKeyDown={handleKeyDown}
              ></span>
              {currentSlide === 0 && (
                <>
                  <span className="title">
                    {titleRoleCoordinatorDiretor[0]}
                  </span>
                  <ul className="list-modal--list">
  {user.personalReferences?.map((item, index) =>
    item.processStatus === "waiting list" ? (
      <li
        key={`unique${item.token}`}
        className={`list-modal--list--item ${
          selectedCandidates.includes(item.token) ? "color-gray" : ""
        }`}
        onClick={() => {
          if (!selectedCandidates.includes(item.token)) {
            selectedCandidate(item.token);
            setHostByPersonalReferences((prevState) => ({
              ...prevState,
              selectedIdHost: [
                ...(prevState?.selectedIdHost || []),
                {
                  idHost: selectedIdHost,
                  id: item.token,
                  name: item.name,
                },
              ],
            }));
          }
        }}
      >
        {item.name}

        {selectedCandidates.includes(item.token) &&
        hostByPersonalReferences.selectedIdHost?.some(
          (host) => host.idHost === selectedIdHost && host.id === item.token
        ) ? (
          <img
            src={CheckList}
            alt=""
            width="20"
            height="15"
            style={{
              marginLeft: "40px",
              marginTop: "5px",
              width: "15px",
              height: "15px",
              position: "absolute",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCandidates((prev) =>
                prev.filter((candidate) => candidate !== item.token)
              );

              setHostByPersonalReferences((prevState) => ({
                ...prevState,
                selectedIdHost: prevState?.selectedIdHost?.filter(
                  (host) => host.id !== item.token
                ) || [],
              }));
            }}
          />
        ) : (
          ""
        )}
      </li>
    ) : null
  )}
</ul>



                </>
              )}
              {currentSlide === 1 && (
                <>
                  <span className="title">
                    {titleRoleCoordinatorDiretor[1]}
                  </span>
                  <ul className="list-modal--list">
                    {director.personalReferences?.map(
                      (item, index) =>
                        item.processStatus === "waiting list" ? (
                          <li
                            key={`unique${item.token}`}
                            className={`list-modal--list--item ${
                              selectedCandidates.includes(item.token) ? "color-gray" : ""
                            }`}
                            onClick={() => {
                              if (!selectedCandidates.includes(item.token)) {
                                selectedCandidate(item.token);
                                setHostByPersonalReferences((prevState) => ({
                                  ...prevState,
                                  selectedIdHost: [
                                    ...(prevState?.selectedIdHost || []),
                                    {
                                      idHost: selectedIdHost,
                                      id: item.token,
                                      name: item.name,
                                    },
                                  ],
                                }));
                              }
                            }}
                          >
                            {item.name}
                    
                            {selectedCandidates.includes(item.token) &&
                            hostByPersonalReferences.selectedIdHost?.some(
                              (host) => host.idHost === selectedIdHost && host.id === item.token
                            ) ? (
                              <img
                                src={CheckList}
                                alt=""
                                width="20"
                                height="15"
                                style={{
                                  marginLeft: "40px",
                                  marginTop: "5px",
                                  width: "15px",
                                  height: "15px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCandidates((prev) =>
                                    prev.filter((candidate) => candidate !== item.token)
                                  );
                    
                                  setHostByPersonalReferences((prevState) => ({
                                    ...prevState,
                                    selectedIdHost: prevState?.selectedIdHost?.filter(
                                      (host) => host.id !== item.token
                                    ) || [],
                                  }));
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        ) : null
                    )}
                  </ul>
                </>
              )}
              <div className="carousel-controls">
                <div
                  className={`carousel-control carousel-control--prev ${
                    currentSlide === 0 ? "disabled" : ""
                  }`}
                  onClick={() => handleNavigation(-1)}
                  onKeyDown={() => {}}
                >
                  <span className="icon icon-previous"></span>
                </div>
                <ul className="carousel-control carousel-control--pagination">
                  <li
                    key={0}
                    className={`item ${0 === currentSlide ? "active" : ""}`}
                  ></li>
                  <li
                    key={1}
                    className={`item ${1 === currentSlide ? "active" : ""}`}
                  ></li>
                </ul>
                <div
                  className={`carousel-control carousel-control--next ${
                    currentSlide === 2 - 1 ? "disabled" : ""
                  }`}
                  onClick={() => handleNavigation(1)}
                  onKeyDown={() => {}}
                >
                  <span className="icon icon-next"></span>
                </div>
              </div>
            </Modal>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              ¡Genial!
              <br />
              <br />
              Has completado el Desafío B.
            </Paragraph>

            <img
              src={GoodHandCoordinator}
              alt="good-hand-coordinator"
              style={{ marginTop: "10px" }}
            />
            <CardCopyKey
              phrase="Preselección Coordinador"
              nameChallenge="Desafío B"
            />
            <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="rules-day-c"
          >
            <br />
            <br/>
            <img src={CoordinatorTools} alt="coordinator-tools"/>
            <Paragraph size="sx" align="center">
              En el momento en el que tus Anfitriones inicien el entrenamiento
              en el ABC Preselección, te contactarán. Tú harás las veces de un
              Aspirante, a punto de iniciar su Día 1.
            </Paragraph>

            <Paragraph size="sx" align="center">
              Cuando estés en las Actividad de la lectura y escritura de
              Propósito, ingresarás a tu Plataforma de Coordinador(a) y en este
              Desafío C, darás clic en alguno de estos dos botones de abajo
              según estés haciendo el entrenamiento con tu Anfitrión #1 o tu
              Anfitrión #2.
            </Paragraph>

            <Button
              className="rules-day-container-button"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="rules-day-c rules-day-container"
          >
            <Paragraph size="sx" align="center">
              A continuación debes activar al Anfitrión que ya haya completado
              el entrenamiento ABC Preselección.
            </Paragraph>

            <GridGiftSwitch>
              {listHosts &&
                listHosts?.map((item) => (
                  <>
                    <div
                      style={{
                        border: hostActive.includes(item?._id)
                          ? "5px solid"
                          : "",
                        borderRadius: hostActive.includes(item?._id)
                          ? "14px"
                          : "",
                        opacity: hostActive.includes(item?._id) ? "1" : "0.5",
                      }}
                    >
                      <GiftSwitchOn
                        role={"host"}
                        nameRole={"Anfitrión(a)"}
                        name={toLowercaseAndCapitalize(item?.name)}
                        range={hostActive.includes(item?._id) ? 8 : 7}
                        onClick={(e) =>
                          putAddToWhatSapp(
                            item?._id,
                            !hostActive.includes(item?._id),
                            e
                          )
                        }
                      />
                    </div>
                  </>
                ))}
            </GridGiftSwitch>

            <Paragraph size="sx" align="center">
              Podrás continuar, cuando hayas iniciado el Entrenamiento con ambos
              Anfitriones.
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              className="rules-day-container-button"
              disabled={hostActive.length === 2 ? false : true}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="rules-day-c"
          >
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado <b>Desafío C.</b>
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />

            <Paragraph
              withBackground={true}
              align="center"
              size="xs"
              padding={16}
            >
              Avísale a tu Director(a) 🟡 que has terminado el Desafío C ¡Estará
              feliz por TI!
              <br />
              <br />
              <b>👋🏻 Ya nos vemos.</b>
            </Paragraph>

            <Button
              className="rules-day-container-button"
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorRulesDay;
