import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import CoordinatorToNewDirector from "../../../resources/images/Group-956.png";
import DirectorImg from "../../../resources/images/director-avatar.png";
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import DirectoAvatar from "../../../resources/images/director-avatar.png";
import PauseIcon from '../../../resources/images/pause.png';
import WarningIcon from '../../../resources/images/warning.png';
import GoodHand from '../../../resources/images/good-hand-coordinator.png';
import ClapHand from "../../../resources/images/clap-hand-coordinator.png";
import Book from "../../../resources/images/book.png";
import TableNewDirectorAngry from "../../../resources/images/table_new_director_angry.png";
import ListTwo from "../../../resources/images/list2.png";

import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import Grupo547 from "../../../resources/images/Group547.png";
import { ReactComponent as RecognitionMedal } from "../../../resources/images/recognition-medal.svg";
import { ReactComponent as RecognitionMedalJustTime } from "../../../resources/images/just-in-time-coordinator.svg";
import JustInTime from "../../../resources/images/just-in-time-coordinator.svg";
import { getCandidates, getCaptainAndHostWithAspirants, getCaptainAndHostWithAspirantsMap, getGeneralUserTable, getListOfHostWithTheirSelectApplicants, postAwardindMedalsToTheRooms } from "../../../middleware/services/epaCoordinatorServices";
import { API, ROLES } from "../../../middleware/utils/constants";
import MedalSwitch from "../../molecules/medal-switch";
import { assignMedal, getHostWinners, pushEvent, pushMedalEvent, saveResponseChallengeEpaRequirements } from "../../../middleware/services/common";
import Card from "../../atoms/card";
import Checkbox from "../../atoms/checkbox";
import CoordinadorByAnfitrionByAspirantesList from "../../pages/epa/coordinador/coodinadorSalaA/CoordinadorByAnfitrionByAspirantesList";
import ActivitySelectedCoordinatorRoomA from "../../pages/epa/coordinador/activitySelectedCoordinatorRoomA/ActivitySelectedCoordinatorRoomA";
import NowYourTurnSelectRoomA from "../../pages/epa/coordinador/NowYourTurnSelectRoomA/NowYourTurnSelectRoomA";
import ActivitySelectedPDLRoomA from "../../pages/epa/coordinador/ActivitySelectedPDLRoomA/ActivitySelectedPDLRoomA";
import ActivitySeletedModalPDLRoomA from "../../pages/epa/coordinador/ActivitySeletedModalPDLRoomA/ActivitySeletedModalPDLRoomA";
import ViewTeamMedalJustinTime from "../../pages/epa/coordinador/ViewTeamMedalJustinTime/ViewTeamMedalJustinTime";
import { EndEpa } from "../../pages/epa/coordinador/EndEpa/EndEpa";
import { ChallengeHonorFirst } from "../director-epa-end-day/ChallengeHonorFirst";
import RequirementsConfirmationActivity from "../../molecules/requirements-confirmation-activity";
import axios from "axios";

const CoordinatorEpaDay = ({ finishChallenge, step, setStep, ...props }) => {
    const { user, eventEpa, setEventEpa, changeRoleContext } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();
    

    const [medals, setMedals] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [captainAndHosts, setCaptainAndHosts] = useState([]);
    const [applicantsAndHosts, setApplicantsAndHosts] = useState([]);
    const [hostWinners, setHostWinners] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [generalUserTable, setGeneralUserTable] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [modalConfirmation, setModalConfirmation] = useState({
        isOpen: false,
        value: ''
    });
    const [numPager, setNumPager] = useState(0);
    // Questions states
    const [prospect, setProspect] = useState(null);
    const [associate, setAssociate] = useState(null);
    const [train, setTrain] = useState(null);
    const [duplicate, setDuplicate] = useState(null);
    const [hold, setHold] = useState(false);
    const [dedicate, setDedicate] = useState(false);
    const [participate, setParticipate] = useState(false);
    const [potentiate, setPotentiate] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);
    const [waitingListModal, setWaitingListModal] = useState(false);
    const fetchData = async () => {
        const list = await getCaptainAndHostWithAspirants(user?._id);

        setCaptainAndHosts(list);
        const listOfHost = await getListOfHostWithTheirSelectApplicants(user?._id);
        setApplicantsAndHosts(listOfHost)

        const hostWinnersList = await getHostWinners(user?._id, user?.role);
        setHostWinners(hostWinnersList);
        const candidatesList = await getCandidates(user?._id);
        setCandidates(candidatesList);
        const generalUserTableList = await getGeneralUserTable(user?._id);
        setGeneralUserTable(generalUserTableList);
    };

    const saveResponseChallengeA = async () => {
        const data = {
            maintainPerformance: hold,
            confidentialityAgreement: confidentiality,
            minutesByWhatsApp: dedicate,
            provisionTwoHours: participate,
            enhanceTheThreeHours: potentiate,
        };

        await saveResponseChallengeEpaRequirements(data, user?._id);
        setStep(step + 1);
    };

    useEffect(() => {
        fetchData();

    }, [eventEpa]);
    const changeRole = (role) => {
        const data = {
            role: `new director`
        };

        axios.put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };


    return <>
        {challengeName === 'Desafío Sala E' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <img src={NewCoordinator} alt="new-host" width={129} height={129} />
                <span className="title">¡Coordinador(a) eres quién medirá el cumplimiento de los tiempos en el EPA!</span>
                <br/>
                <Paragraph size="s" align="justify" className="p-room-p-0">
                    En el desafío de la Sala Épica tus funciones son:
                    <br />
                    <br />
                    1. Entregar las medallas de Manejo del Tiempo 🥇 a tus dos Anfitriones por sus Notas de voz en el grupo de WhatsApp.
                    <br />
                    <br />
                    2. Compartir tu PDL. (Podrás ganar medalla de Manejo del Tiempo 🥇).
                    <br />
                    <br />
                    3. Entregar la medalla de Justo a Tiempo ⏱️ de equipo si completan las pausas del video de la Sala Épica máximo 30 minutos después de la hora del inicio del EPA.
                </Paragraph>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Modal className="confirmation-modal step epa-day-modal" isOpen={step === 2 || step === 4 || step === 7}>
                <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
                <span className="title">¡Coordiandor(a)!</span>
                <Paragraph align="center" size="s">
                    Te encuentras en una pantalla de Pausa Global, ningún miembro del EPA podrá avanzar hasta que el(la) Director(a) lo permita.
                    <br />
                    <br />
                    Procura estar atento(a) a las indicaciones para completar las actividades de forma sincronizada.
                </Paragraph>
          
                <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
               
            </Modal>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center" className="color-paragraph">
                    <b>Actividad PDL</b>
                </Paragraph>
                <Paragraph size="s" align="center" className="color-paragraph p-room-p-0">
                    ¡Coordinador(a)! Te encuentras en el Desafío de la Sala Épica, aquí compartirás a tu equipo ACDC tu PDL (Propósito, Destino y Legado), sigue las instrucciones dadas por el Director(a) del EPA para realizar esta actividad.
                </Paragraph>
                <div className="warning-card-room-e-1  warning-card-bottom">
                    <img src={WarningIcon} alt="warning" className="warning " width={98} height={93} />
                    </div>
                <div className="warning-card  warning-card-box-room-e-1 ">
                  
                    <Paragraph size="s" align="center" className="color-paragraph p-room-p-2">
                        Recuerda que para ganar la medalla, la Nota de voz debe ser de 0:50 a 1:10.
                    </Paragraph>
                </div>
                <Button
                    onClick={() => setStep(step + 1)}
                >
                    Continuar
                </Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <Paragraph size="s" align="center" className="p-room-p-0">
                    <b>Actividad PDLs.</b>
                    <br />
                    <br />
                    ¡Coordinador(a)!, en esta actividad también deberás entregar las medallas de Manejo del Tiempo 🥇 a tu Director(a) y Anfitriones(as).
                </Paragraph>
                <br/>
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <TimeManagementMedal className={user?.role} width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            Presiona SÍ, en el participante que haya ganado medalla de Manejo del Tiempo
                            y No, en el caso contrario.
                        </Paragraph>
                    </div>
                    {captainAndHosts?.map((candidate) => !candidate.role.startsWith("new") && <MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.name}
                        circle={ROLES[candidate.role]?.circle}
                        disabled={candidate.disabled}
                        onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement', value, candidate._id, idChallenge);
                        }} />)}
                </div>
                <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
            </Step>
            <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-day-container host-alignment-day-container coordinator-epa-container">
                <span className="title">¡Felicitaciones!<br />Tu participación compartiendo tu PDL con el equipo fue perfecta.</span>
                <br/>
                <img src={GoodHand} alt="good-hand" />
                <Paragraph size="s" align="center">
                    ¡Has ganado una medalla de <b>Manejo del  Tiempo!</b>
                </Paragraph>
                <br/>
                <TimeManagementMedal className={user?.role} />
                <br/>
                <Button onClick={() => setStep(step + 1)} >Ver vídeo</Button>
                <Button onClick={() => setStep(step + 1)} style="ghost" size="small">Continuar</Button>
            </Step>
            <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <br/>
                <Paragraph size="s" align="center" className="p-room-p-2">
                    <b>¡Coordinador(a)! estás encargado de otorgar la medalla en equipo de Justo a Tiempo
                       <br/> Sala Épica.</b>
                </Paragraph>
                <Paragraph size="s" align="center" className="p-room-p-0">
                    Para ganar esta medalla de equipo, tu Director(a) debió haber completado la pausa #4 de la Sala Épica máximo 30 minutos después de la hora de inicio del EPA.
                </Paragraph>
                <br/>
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <TimeManagementMedal className={user?.role} width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            Presiona SÍ, para otorgar la medalla en equipo de <b>Justo a Tiempo</b> equipo; y NO, en caso contrario.
                        </Paragraph>
                    </div>
                    {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled={index > 0} onClick={(value) => {
                        setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        setModalConfirmation({
                            ...modalConfirmation,
                            value: value === 'No' ? value.toUpperCase() : value,
                        });
                    }} />)}
                </div>
                <Button onClick={() => setModalConfirmation({
                    ...modalConfirmation,
                    isOpen: true,
                })} role={user?.role} disabled={rooms.length === 0}>Continuar</Button>
            </Step>
            <Modal className="host-alignment-day-container epa-confirmation-modal" isOpen={modalConfirmation?.isOpen} onClose={() => setModalConfirmation(false)}>
                <Paragraph size="s" align="center" className="p-small-0" >
                    ¿Estás seguro(a) que tu equipo {modalConfirmation?.value} ganó la medalla?
                </Paragraph>
                <br/>
                <div className="buttons">
                    <Button size="small" onClick={async () => {
                        const response = await postAwardindMedalsToTheRooms(user?._id, idChallenge);
                        if (response?.success) {
                            setModalConfirmation({
                                ...modalConfirmation,
                                isOpen: false,
                            });
                            const finishTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Bogota', hour12: false });
                            setCurrentTime({
                                hours: finishTime.split(':')[0],
                                minutes: finishTime.split(':')[1],
                                seconds: finishTime.split(':')[2]
                            });
                            setStep(step + 1);
                        }
                    }}>Sí</Button>
                    <Button size="small" onClick={() => setModalConfirmation({
                        ...modalConfirmation,
                        isOpen: false,
                    })}>No</Button>
                </div>
            </Modal>
            <Modal isOpen={step === 9} className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' && <Paragraph align="center" size="s">
                        ¡Felicitaciones equipo ACDC!
                        <br />
                        Lograron completar las actividades de la Sala de Admisiones <b>Justo a Tiempo.</b>
                    </Paragraph>}
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' && <img src={JustInTime} alt="Just in Time" style={{ opacity: currentTime?.hours === '09' && currentTime?.minutes === '00' ? '1' : '0.5' }} />}
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' ? <><Paragraph align="center" size="s">
                        ¡Has ganado una Medalla en equipo de <b>Justo a Tiempo!</b>
                    </Paragraph>
                    </> :
                        <>
                        <Paragraph align="center" size="s" >
                            <b>¡Equipo ACDC!</b>
                            <br />
                            <br />
                            ¡Ups! Lamentablemente, no cumplieron con el tiempo establecido.
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo ¡Podrán mejorar su desempeño durante el EPA!
                        </Paragraph>
                        </>

                    }
                    <br/>
                    
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </div>
            </Modal>
            <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'}>
            <div>
                    <img src={TableNewDirectorAngry} width="386"/>
                    <Paragraph align="left" className="text-room-table" size="s">
                    “Necesitamos líderes con un idealismo inspirador, líderes que cuenten con grandes visiones, que sueñen en grande y que luchen por esos sueños para que se vuelvan realidad, personas que logren encender a los demás con la chispa de sus propias almas apasionadas”
                            <br />
                            <br />
                            <b>-Theodore Roosevelt.</b>
                    </Paragraph>
                </div>
                <div className="phrase-container">
                    <img src={Book} alt="Book" className="book" />
                    <Paragraph size="s" align="left" className="gift-text-phrase ">
                        Fragmentos del libro
                        <br />
                        <b>Lanzando una Revolución sobre el Liderazgo.</b>
                    </Paragraph>
                </div>
                <br/>
                <br/>
                <br/>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <Paragraph size="s" align="center">
                    <b>¡Genial!</b>
                </Paragraph>
                <Paragraph size="s" align="center" className="p-small-1">
                    Has completado el Desafío de la <b>Sala Épica.</b>
                </Paragraph>
                <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                <Paragraph size="s" align="center" className="background-p">
                    Ya puedes iniciar el desafío de la <b>Sala Plataforma.</b>
                    <br />
                    <br />
                    👋🏻 Ya nos vemos.
                </Paragraph>
                <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
            </Step>
        </>}
        {challengeName === 'Desafío Sala P' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <img src={NewCoordinator} alt="new-host" width={129} height={129} />
                <span className="title">¡Coordinador(a) eres quién medirá el cumplimiento de los tiempos en el EPA!</span>
                <br/>
                <Paragraph size="s" align="center" className="p-room-p-0">
                    Aquí en la Sala Plataforma tus funciones son:
                </Paragraph>
                <br/>
                <Paragraph size="s" align="left" className="p-room-p-0">
                    1. Entregar las medallas de Manejo del Tiempo 🥇 a tus dos Anfitriones por compartir su experiencia en su rol a través de Notas de voz en el grupo de WhatsApp.
                    <br />
                    <br />
                    2. Compartir tu experiencia en el rol de Coordinador(a) (Podrás ganar medalla de Manejo del Tiempo 🥇).
                    <br />
                    <br />
                    3. Entregar la medalla de Justo a Tiempo ⏱️ de equipo si completan las pausas del video de la Sala Plataforma máximo 30 minutos después de la hora del inicio del EPA.
                </Paragraph>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <br/>
                <Paragraph size="s" align="center" className="p-room-p-0">
                    <b>Actividad compartir Juego de Pulgares</b>
                    <br />
                    <br />
                    Los Aspirantes recibirán automáticamente su medalla al hacer la puntuación
                </Paragraph>
                <br/>
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <RecognitionMedal className="candidate" width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            En esta tabla puedes visualizar los miembros del equipo que ganaron o no, su medalla de <b>Reconocimiento</b>
                        </Paragraph>
                    </div>
                    {candidates?.map((candidate) => !candidate.role.startsWith("new") &&<MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.name}
                        circle={ROLES[candidate.role]?.circle}
                        disabled={candidate.disabled}
                        onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement', value, candidate._id, idChallenge);
                        }} />)}
                </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
                <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={/*medals.length === 0*/ false}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <img src={NewCoordinator} alt="new-host"  />
                <Paragraph size="s" align="center" className="p-room-p-0">
                    Espera a que el resto del equipo lleguen a la pausa #8 para poder continuar con con la actividad de los Anfitriones compartiendo su experiencia.
                </Paragraph>
                <img src={DirectoAvatar} alt="new-host" width={129} height={129} />
                <Paragraph size="s" align="center" className="p-room-p-1">
                    Podrás continuar una vez tu Director(a) confirme que el equipo está sincronizado en la pausa #8.
                </Paragraph>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <br/>
                <Paragraph size="s" align="center" className="p-room-p-0">
                    <b>Actividad compartir experiencia del rol</b>
                    <br />
                    <br />
                    Tus dos Anfitriones van a compartir cómo fue su experiencia cumpliendo las funciones de su rol. Entregarás la medalla de Manejo del Tiempo 🥇, si sus Notas de voz están de 0:50 a 1:10.
                </Paragraph>
                <br />
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <TimeManagementMedal className="host" width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            Presiona SÍ, en el participante que haya ganado medalla de Manejo del Tiempo
                            y No, en el caso contrario
                        </Paragraph>
                    </div>
                    {captainAndHosts?.filter((candidate) => candidate.role === 'host')?.map((candidate) => <MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.name}
                        circle={ROLES[candidate.role]?.circle}
                        disabled={candidate.disabled}
                        onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
               
                            assignMedal('timeManagement', value, candidate._id, candidate.challenges[0]?.timeManagement);
                        }} />)}
                </div>
                <br />
                <br />
                <Paragraph size="s" align="center" className="p-room-p-1">
                    Podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center" className="color-paragraph">
                    <b>¡Coordinador(a)!</b>
                </Paragraph>
                <Paragraph size="s" align="center" className="color-paragraph p-room-p-1">
                    Cuando compartas tu experiencia de tu rol y la razón por la que realizaste la compra del paquete de productos con el proveedor seleccionado, ten en cuenta que en ese momento ya te habías asegurado con tus dos Anfitriones quiénes habían preseleccionado a un total de 6 Aspirantes, y estos Aspirantes fueron validados por un total de 36 referencias personales.
                </Paragraph>
                <br />

                <div className="warning-card  warning-card-box ">
                    <img src={WarningIcon} alt="warning" className="warning warning-card-0 warning-card-bottom" width={98} height={93} />
                    <br />

                    <Paragraph size="s" align="center" className="color-paragraph p-room-p-2">
                        Recuerda que ganarás la medalla de Manejo del Tiempo 🥇 si tu Nota de voz está entre 1:00 y 2:00.
                    </Paragraph>
                    <br />
                </div>
                <Button
                    onClick={() => setStep(step + 1)}
                >
                    Continuar
                </Button>
            </Step>
            <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <span className="title">Resultados de Liderazgo Rol Coordinador(a)</span>
                <table className="table-results">
                    <tr>
                        <td align="left" className="total-title">Total Anfitriones</td>
                        <td align="center">{generalUserTable?.totalHost}</td>
                        <th align="center">G1</th>
                    </tr>
                    <tr>
                        <td align="left" className="total-title">Total Aspirantes</td>
                        <td align="center">{generalUserTable?.totalCandidate}</td>
                        <th align="center">G2</th>
                    </tr>
                    <tr>
                        <td align="left" className="total-title">Total Validadores</td>
                        <td align="center">{generalUserTable?.totalValidator}</td>
                        <th align="center">G3</th>
                    </tr>
                    <tr>
                        <th align="left" className="total-title">Total</th>
                        <th align="center">{generalUserTable?.total}</th>
                    </tr>
                </table>
                <Paragraph size="xs" align="justify">
                    Número de Validadores mínimo por Aspirante: 6
                    Número de Aspirantes por Anfitrión: 3
                </Paragraph>
                <Button onClick={() => setStep(step + 1)} className="margin-top">Continuar</Button>
            </Step>
            <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                {captainAndHosts?.map((generation) => generation.role === 'host' && <div className="generation-map">
                    <div className={`first generation`}>
                        <div className={`info ${generation.role}`}>
                            <span className="name">{generation.name}</span>
                            <span className="role"><b>{ROLES[generation.role]?.circle}</b>{ROLES[generation.role]?.roleName}</span>
                        </div>
                    </div>
                    <div className="second generation">
                        {generation.children?.map((secondGeneration) => <div className={`info ${secondGeneration.role}`}>
                            <span className="name">{secondGeneration.name}</span>
                            <span className="role"><b>{ROLES[secondGeneration.role]?.circle}</b>{ROLES[secondGeneration.role]?.roleName}</span>
                        </div>)}
                    </div>
                    <div className="title generation">
                        {generation.children?.map((secondGeneration) => <div>
                            Validadores
                            <span className="icon icon-chevron-back down"></span>
                        </div>)}
                    </div>
                    <div className="third generation">
                        {generation.children?.map((secondGeneration) => <div className="column">
                            {secondGeneration.personalReferences?.map((thirdGenertion) => <div className={`info`}>
                                <span className="name">{thirdGenertion.name}</span>
                            </div>)}
                        </div>)}
                    </div>
                </div>)}

                <Button size="small" style="ghost">Compartir imagen</Button>
                <br />
                <br />
                <br />

                <Button onClick={() => setStep(step + 1)} >Continuar</Button>

                <div className="button-1">

                </div>
            </Step>
            <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <br/>
                <Paragraph size="s" align="center" className="p-room-p-0">
                    <b>Actividad compartir experiencia del rol</b>
                    <br />
                    <br />
                    Recuerda que para ganar la medalla, la Nota de voz debe ser de 0:50 a 1:10.
                    <br/>
                    <br/>
                </Paragraph>
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <TimeManagementMedal className="coordinator" width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            En esta tabla puedes visualizar los miembros del equipo que ganaron o no, su medalla de<strong> Manejo del Tiempo</strong>
                        </Paragraph>
                    </div>
                    {captainAndHosts?.filter((candidate) => candidate.role === 'coordinator' )?.map((candidate) => <MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.name}
                        circle={ROLES[candidate.role]?.circle}
                        disabled
                        onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement', candidate.challenges[0]?.timeManagement>0?'si':'no', candidate._id, candidate.challenges[0]?.timeManagement);
                        }} />)}
                </div>
                <br/>
                <br/>
                <Paragraph size="xs" align="center" className="p-room-p-1">
                    Podrás continuar una vez tu Director(a)<br/> confirme que se dieron o no, todas las medallas.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)} role={user?.role}>Continuar</Button>
            </Step>
            <Modal isOpen={step === 9} className="confirmation-register-modal ">
                <br />
                <span className="title-gift-span">¡Felicitaciones!

                </span>
                <span className="title-gift-span">
                    Tu participación fue perfecta

                </span>

                <div className="avatar-modal-coordinator-room-p">
                    <img src={Grupo547} />
                </div>

                <Paragraph size="s" align="center">
                    ¡Has ganado una medalla de <b> Manejo del  Tiempo!</b>
                </Paragraph>
                <br />
                <div className="confirmation-register-container">

                    {/*<img src={MEDALS[medalModal?.typeMedals]?.img} alt="Time Management" style={{ opacity: medalModal.medal ? '1' : '0.5' }} width={90} height={81} />
                    {medalModal.medal ? <>
                        <Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                        </Paragraph></> :
                        <Paragraph align="center" size="s">
                            <b>¡Ups!</b>
                            ¡Lamentablemente hoy no has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                            <br />
                            <br />
                            ¡Mañana podrás ganar {MEDALS[medalModal?.typeMedals]?.text} si completas el Desafío C antes de las 9:30 de la noche!
                        </Paragraph>
                    }*/}
                    <Button size="small" onClick={() => {
                        setStep(step + 1);
                    }}>¡Vale!</Button>
                </div>
            </Modal>
            <Modal isOpen={step === 10} className="confirmation-register-modal confirmation-coordinator-register-modal">
                <br />

                <Paragraph size="s" align="center">
                ¡Ups! Lamentablemente, no cumpliste con el tiempo establecido.
                </Paragraph>
<br/>

                <Paragraph size="s" align="center">
                En esta ocasión, no ganaste medalla de Manejo del Tiempo ¡Podrás mejorar tu desempeño durante el EPA!
                </Paragraph>
                <br />
                <div className="confirmation-register-container">

                    <Button size="small" onClick={() => {
                        setStep(step + 1);
                    }}>¡Vale!</Button>
                </div>
                <br/>
            </Modal>
            <Step order={10} visibility={step === 11 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                <Paragraph size="s" align="center" className="p-room-p-0">
                    <b>¡Coordinador(a)! estás encargado de otorgar la medalla en equipo de Justo a Tiempo
                        <br/>Sala Épica.</b>
                        <br/>
                        <br/>
                </Paragraph>
                <Paragraph size="s" align="center" className="p-room-p-1">
                    Para ganar esta medalla de equipo, tu Director(a) debió haber completado la pausa #4 de la Sala Épica máximo 30 minutos después de la hora de inicio del EPA.
                </Paragraph>
                <br/>
                <div className="tutorial-time-management">
                    <div className="tutorial-time-management__info">
                        <TimeManagementMedal className={user?.role} width="33.75" height="29.9" />
                        <Paragraph size="s" align="center">
                            Presiona SÍ, para otorgar la medalla en equipo de <b>Justo a Tiempo</b> equipo; y NO, en caso contrario.
                        </Paragraph>
                    </div>
                    {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled={index !== 1} onClick={(value) => {
                        setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        setModalConfirmation({
                            ...modalConfirmation,
                            value: value === 'No' ? value.toUpperCase() : value,
                        });
                    }} />)}
                </div>
                <Button onClick={() => setModalConfirmation({
                    ...modalConfirmation,
                    isOpen: true,
                })} role={user?.role} disabled={rooms.length === 0}>Continuar</Button>
            </Step>
            <Modal className="host-alignment-day-container epa-confirmation-modal" isOpen={modalConfirmation?.isOpen} onClose={() => setModalConfirmation(false)}>
                <Paragraph size="s" align="center" >
                    ¿Estás seguro(a) que tu equipo {modalConfirmation?.value} ganó la medalla?
                </Paragraph>
                <div className="buttons">
                    <Button size="small" onClick={async () => {
                        const response = await postAwardindMedalsToTheRooms(user?._id, idChallenge);
                        if (response?.success) {
                            setModalConfirmation({
                                ...modalConfirmation,
                                isOpen: false,
                            });
                            const finishTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Bogota', hour12: false });
                            setCurrentTime({
                                hours: finishTime.split(':')[0],
                                minutes: finishTime.split(':')[1],
                                seconds: finishTime.split(':')[2]
                            });
                            setStep(step + 1);
                        }
                    }}>Sí</Button>
                    <Button size="small" onClick={() => setModalConfirmation({
                        ...modalConfirmation,
                        isOpen: false,
                    })}>No</Button>
                </div>
            </Modal>
            <Modal  className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' && <Paragraph align="center" size="s">
                        ¡Felicitaciones equipo ACDC!
                        <br />
                        Lograron completar las actividades de la Sala de Admisiones <b>Justo a Tiempo.</b>
                    </Paragraph>}
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' && <img src={JustInTime} alt="Just in Time" style={{ opacity: currentTime?.hours === '09' && currentTime?.minutes === '00' ? '1' : '0.5' }} />}
                    {currentTime?.hours === '09' && currentTime?.minutes === '00' ? <><Paragraph align="center" size="s">
                        ¡Has ganado una Medalla en equipo de <b>Justo a Tiempo!</b>
                    </Paragraph>
                    </> :
                        <Paragraph align="center" size="s">
                            <b>¡Equipo ACDC!</b>
                            <br />
                            <br />
                            ¡Ups! Lamentablemente, no cumplieron con el tiempo establecido.
                            En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo ¡Podrán mejorar su desempeño durante el EPA!
                        </Paragraph>
                    }
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </div>
            </Modal>
            <Step order={11} visibility={step === 12 ? 'visible' : 'hidden'} className="coordinator-epa-container">
                <Paragraph size="s" align="center">
                    <b>¡Genial!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Has completado el Desafío de la <b>Sala Plataforma.</b>
                </Paragraph>
                <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                <Paragraph size="s" align="center" className="background-p">
                    Ya puedes iniciar el desafío de la <b>Sala Admisiones.</b>
                    <br />
                    <br />
                    👋🏻 Ya nos vemos.
                </Paragraph>
                <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
            </Step>
        </>}
        {challengeName === 'Desafío Sala A' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                <span className="title">Actividad confirmación de requisitos</span>
                <Paragraph size="s" align="center">
                    Coordinador(a), los Aspirantes de tus Anfitriones en el PREPA confirmaron los primeros 4 requisitos.
                    <br />
                    <br />
                    A continuación te los recordamos:
                    <br/>
                    <br/>
                </Paragraph>
                <RequirementsConfirmationActivity
                            className=''
                            hold={hold}
                            setHold={setHold}
                            dedicate={dedicate}
                            setDedicate={setDedicate}
                            participate={participate}
                            setParticipate={setParticipate}
                            potentiate={potentiate}
                            setPotentiate={setPotentiate}
                            />
                <br/>
                <Button onClick={() => setStep(step + 1)} disabled={!hold || !dedicate || !participate || !potentiate}>Continuar</Button>
            </Step>

            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                <br/>
                <Paragraph size="s" align="center" className="custom-border-color ">
                    <div className="border-room-a-1">
                    Sin embargo, para seguir avanzando necesitas confirmar el <b>Quinto requisito</b> que es esencial para ser parte de nuestra <br/> comunidad épica:
                    </div>
                </Paragraph>
                <br/>
                <Checkbox
                    name="confidentiality"
                    label="5. Confidencialidad absoluta de lo que has vivido durante el proceso incluido el EPA hasta que seas Capitán."
                    checked={confidentiality}
                    onChange={(e) => setConfidentiality(e.target.checked)}
                    className="coordinator-room-a-checkbox checkbox-room-p-1"
                />
                <br/>
                <img src={ListTwo} alt="List" width={280} />
                <br/>
                <br/>
                <Button onClick={saveResponseChallengeA} disabled={!confidentiality}>Continuar</Button>
            </Step>

            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                <CoordinadorByAnfitrionByAspirantesList captainAndHosts={captainAndHosts} />

                <Button onClick={() => setStep(step + 1)} className="margin-top">Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                <ActivitySelectedCoordinatorRoomA applicantsAndHosts={applicantsAndHosts} />
                <Button onClick={() => setStep(step + 1)} className="margin-top">Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                <NowYourTurnSelectRoomA setStep={setStep} step={step} applicantsAndHosts={applicantsAndHosts} />

            </Step>
            <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-day-container">
                <ActivitySelectedPDLRoomA setNumPager={setNumPager} setStep={setStep} step={step} applicantsAndHosts={applicantsAndHosts} />
            </Step>

            <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="epa-day-container">
                <ActivitySeletedModalPDLRoomA numPager={numPager} setNumPager={setNumPager} setStep={setStep} step={step} applicantsAndHosts={applicantsAndHosts} />
            </Step>
            <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-day-container">
                <ViewTeamMedalJustinTime setStep={setStep} step={step} hostWinners={hostWinners} />
            </Step>


            <Modal isOpen={step === 9} className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    <Paragraph align="center" size="s">
                        <b>¡Felicitaciones equipo ACDC!</b>
                        <br />
                        Lograron completar las actividates de la Sala Épica <b>Justo a Tiempo</b>
                        <br />
                        <br />
                        <span className="just-in-time-coordinator">
                            <RecognitionMedalJustTime className="candidate" width="310" height="84" />
                        </span>

                        <br />
                        <br />
                        ¡Han ganado una medalla en equipo de <b>Justo a Tiempo</b>!
                        <br/>
                        <br/>
                    </Paragraph>

                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </div>
            </Modal>

            <Modal isOpen={step === 10} className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    <Paragraph align="center" size="s">
                        <b>¡Equipo ACDC!</b>
                        <br />
                        !Ups¡ Lamentablemente, no  cumplieron con el tiempo establecido.
                        En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo !Podrán mejorar su desempeño durante el EPA!
                        <br />
                        <br />
                    </Paragraph>
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </div>
            </Modal>
            <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-day-container">
                <EndEpa finishChallenge={finishChallenge} setStep={setStep} step={step} />


            </Step>


        </>}


        {challengeName === 'Cambio de Rol' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
            <br/>
            
            <Paragraph size="s" align="center" className="card-host-captain">
            Actividad Cambio de Rol
                <br />
           
              </Paragraph>
                   <img src={CoordinatorToNewDirector} alt="Coordinator To New Director" />
                    <Paragraph size="s" align="center" >
                    Presiona el botón “Convertirme en Nuevo(a) Director(a)” si lo ves habilitado, de lo contrario espera las indicaciones del Nuevo Director.
                    </Paragraph>
                    <Button role="director"
                        onClick={() => {
                            changeRole();
                        }}
                      
                    >
                       Convertirme en Nuevo(a) Director(a)
                    </Button>
          
                   
                </Step>

        
    
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                    ¡Felicitaciones!
                    </Paragraph>
                  
                    <Paragraph size="s" align="center">
                    Tus dos Anfitriones han seleccionado a sus Aspirantes ¡Ya estás listo(a) para pasar al siguiente nivel como Formador(a) de líderes! 
                   <br/>
                   <br/>
                   ¡Bienvenido(a) a nuestra comunidad épica!
                    </Paragraph>
                    <img src={DirectorImg} alt="New Director" width={194} height={194} />
                    
                    <Paragraph size="s" align="center">
                    Ahora que eres el nuevo(a) Director(a),
                    continúa con el EPA en tu nuevo rol.
                   </Paragraph>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button id="finish" onClick={() => finishChallenge(null)} style="ghost" size="small">Continuar</Button>
                </Step>


        </>}
    </>;
};

export default CoordinatorEpaDay;   