const formatDate = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const options = { weekday: 'long', day: 'numeric', month: 'short' };
    const dateObj = new Date(splitDate.year, splitDate.month - 1, splitDate.day);
    let formattedDate = dateObj.toLocaleDateString('es-ES', options);
    const [dayName, day, month] = formattedDate.split(' ');
    const capitalizedDayName = dayName?.charAt(0).toUpperCase() + dayName?.slice(1);
    const capitalizedMonth = month?.charAt(0).toUpperCase() + month?.slice(1).replace('.', '') + '.';
    return `${capitalizedDayName.replace(',', '')} ${day} de ${capitalizedMonth}`;
};

const formatDateLong = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    const dateObj = new Date(splitDate.year, parseInt(splitDate.month) - 1, splitDate.day);
    let formattedDate = dateObj.toLocaleDateString('es-ES', options);
    const [dayName, day, aux, month] = formattedDate.split(' ');
    const capitalizedDayName = dayName?.charAt(0).toUpperCase() + dayName?.slice(1);
    const capitalizedMonth = month?.charAt(0).toUpperCase() + month?.slice(1).replace('.', '');
    return `${capitalizedDayName.replace(',', '')} ${day} ${aux} ${capitalizedMonth}`;
};


const formatDateLines = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const newDate = new Date(splitDate.year, splitDate.month-1, splitDate.day);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    
    const formattedDate = newDate.toLocaleDateString('es-ES', options)
        .replace('.', '')
        .replace(' ', ' de ')
        .replace('ene', 'Ene -')
        .replace('feb', 'Feb -')
        .replace('mar', 'Mar -')
        .replace('abr', 'Abr -')
        .replace('may', 'May -')
        .replace('jun', 'Jun -')
        .replace('jul', 'Jul -')
        .replace('ago', 'Ago -')
        .replace('sep', 'Sep -')
        .replace('oct', 'Oct -')
        .replace('nov', 'Nov -')
        .replace('dic', 'Dic -');
        const mesesCortos = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const fecha = new Date(splitDate.year, splitDate.month-1, splitDate.day);
        const dia = fecha.getDate();
        const mes = mesesCortos[fecha.getMonth()]; 
        const anio = fecha.getFullYear();
        const fechaCorta = `${dia} de ${mes} - ${anio}`;

    return fechaCorta;
};

const reverseDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
};

function formatearDate(date) {
    const months = [
      'Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.',
      'Jul.', 'Ago.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} de ${month} del ${year}`;
  }
  function getHoraAmAndPm(date) {
    let hours = date.getHours(); 
  const minutes = date.getMinutes().toString().padStart(2, '0'); 
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const periodo = hours >= 12 ? 'PM' : 'AM'; 
  hours = hours % 12 || 12; 
  return `${hours}:${minutes}: ${periodo}`;
  }

  const reverseFomatDate = (dateString) => {
    const date = new Date(dateString); // Convierte la cadena de texto a un objeto Date
    const day = date.getDate().toString().padStart(2, "0"); // Obtiene el día con dos dígitos
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Obtiene el mes (se suma 1 porque es base 0)
    const year = date.getFullYear(); // Obtiene el año
    return `${day}/${month}/${year}`; // Devuelve en formato DD/MM/YYYY
  };

  // Función para restar días a una fecha

  const subtractDays = (dateString, daysToSubtract) => {
    if (!dateString || typeof dateString !== "string" || dateString === "null") {
      console.error("Fecha inválida proporcionada a subtractDays:", dateString);
      return null;
    }
  
    // Normalizar la cadena: eliminar "del", "de", y los puntos
    const normalizedDate = dateString
      .replace(/ de /g, " ")   // Reemplaza " de " por un espacio
      .replace(/del /g, "")    // Elimina "del"
      .replace(/\./g, "")      // Elimina los puntos
      .trim();                // Elimina espacios al inicio y final
  
    // Separar día, mes y año
    const [day, month, year] = normalizedDate.split(" ");
    const months = {
      Ene: 0,
      Feb: 1,
      Mar: 2,
      Abr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Ago: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dic: 11,
    };
  
    // Validar que el mes exista en el objeto months
    if (!(month in months) || isNaN(parseInt(day)) || isNaN(parseInt(year))) {
      console.error("Formato de fecha no válido:", dateString);
      return null;
    }
  
    // Crear la fecha en formato válido
    const date = new Date(year, months[month], parseInt(day));
    if (isNaN(date.getTime())) {
      console.error("Fecha inválida después de procesar:", dateString);
      return null;
    }
  
    // Restar los días
    date.setDate(date.getDate() - daysToSubtract);
    console.log("date", date);
  
    // Retornar la fecha en formato "DD de Mes del YYYY"
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).replace(/ de /g, " ");
  };
  const parseDate = (date) => {
    const months = {
      Ene: 0,
      Feb: 1,
      Mar: 2,
      Abr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Ago: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dic: 11,
    };
  
    const [day, month, year] = date.split(" ");
    return new Date(parseInt(year), months[month.toLowerCase()], parseInt(day));
  };  
  
export { formatDate, formatDateLong, formatDateLines,reverseDate ,formatearDate,getHoraAmAndPm,reverseFomatDate,subtractDays,parseDate};