import {  ROLES } from "../../../../../middleware/utils/constants";
import Checkbox from "../../../../atoms/checkbox";

import MenuAspiranteInfo from './MenuAspitanteInfo';
import Paragraph from '../../../../atoms/paragraph';
import "./CoordinadorByAnfitrionByAspirantesList.scss"
import { useContext, useEffect, useState } from "react";
import { getCaptainAndHostWithAspirants } from "../../../../../middleware/services/epaCoordinatorServices";
import { UserContext } from "../../../../../middleware/providers/user-context";

const CoordinadorByAnfitrionByAspirantesList = ({ captainAndHosts }) => {
    const { user } = useContext(UserContext);
    const [captain, setCaptain] = useState();
    useEffect(()=>{
        DataCaptaiAndHosts()
    },[])
   const DataCaptaiAndHosts=async()=>{
    const list = await getCaptainAndHostWithAspirants(user?._id);
    setCaptain(list)
   }

    return (
        <>
            {captain?.map(
                (generation) => generation.role === 'host'&& <div className="generation-map">
                <Paragraph size="xs" align="center" className="custom-border-color-room-a" >
                
                    <div className={`info ${generation.role}`} >
                        <span className="name"><b>{generation.name}</b></span>
                    </div>
                    <div className={`info ${generation.role}`}>
                        <span className="role">{ROLES[generation.role==='host']?.circle}{ROLES[generation.role==='host']?.roleName}</span>
                    </div>
              
              
                </Paragraph>
                {
                generation.children?.map((candidate) =>candidate.role=="candidate"&&(
                <>    
                
                <MenuAspiranteInfo
                    className="epa-requirements epa-list-candiate"
                    orientation="row"
                    name={candidate.name}
                    role={candidate.role || 'candidate'}
                    nameRole={ROLES[candidate.role || 'candidate'].roleName}
                    range={7}
                    icon={ROLES[candidate.role || 'candidate'].circle}
                >
                    <div className="epa-requirements__status" style={{margin:'0'}}>
                        {Object.keys(candidate.epaParticipationRequirement || {
                            maintainPerformance: false,
                            confidentialityAgreement: false,
                            minutesByWhatsApp: false,
                            provisionTwoHours: false,
                            enhanceTheThreeHours: false,
                        }).map((index) => <Checkbox name="hold"
                            checked={candidate?.epaParticipationRequirements?.[index]}
                            disabled
                        />)}
                    </div>
                </MenuAspiranteInfo>
                </>
                ))
            }
            </div>
        )
        }
           
        </>
    )
}

export default CoordinadorByAnfitrionByAspirantesList;