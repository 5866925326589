import NewHost from "../../../resources/images/new-host.png";
const MenuUserInfoOn = ({ name, role, nameRole, range, orientation = 'column', children, extraContent, icon, className = '', style, headingAlign = 'center', size}) => {

    return (
        <div className={`menu-user-info menu-user-info--${role} ${orientation} ${className}`} style={style}>
            <div className={`progress-${range}-8 ${size}`}>
               <img src={NewHost} alt="new-host" width="104" height="104"/>
            </div>
            <div className="menu-user-info-title">
                <h4 className={`heading ${headingAlign}`}>{name}</h4>
                <span>{nameRole}{icon}</span>
                {children}
            </div>
            {extraContent}
        </div>
    );
};

export default MenuUserInfoOn;