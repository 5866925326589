import { Toggle } from "../../atoms/switch";
import MenuUserInfo from "../menu-user-info";

const GiftSwitch = ({ name, role, nameRole, range, onClick, items }) => {
    return (
        <div className="gift-switch">
            <MenuUserInfo name={name} role={role} nameRole={nameRole} range={range} />
            {!items && <Toggle onClick={onClick} />}
            {items?.map((item) => <div className={`content-buy ${item.wait && 'wait'}`}>
                <img src={item.image} alt={item.title} style={{width: item.width || '30px'}} />
                <span className={`${!item.wait && 'icon-state-selected'} icon`}>{item.wait && '...'}</span>
            </div>)}
        </div>
    );
};

export default GiftSwitch;