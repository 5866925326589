import React, { useContext } from "react";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { formatDate, formatearDate, getHoraAmAndPm, parseDate, subtractDays } from "../../../middleware/utils/formatDate";

import pen from "../../../resources/images/pen.png";
import { UserContext } from "../../../middleware/providers/user-context";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

const circle = {
  host: "⚪",
  "new host": "⚪",
  coordinator: "🔴",
  "new coordinator": "🔴",
  director: "🟡",
  captain: "🔵",
};
export const NewCoordinatorsSchedule = ({
  setModalSchedule,
  setAcceptData,
  setStep,
  step,
  setIdCoordinator,
  captainAndHosts,
}) => {
  const { user } = useContext(UserContext);

  const handleDateSelection = (coordinator,editDate) => {
     setIdCoordinator(coordinator?._id);

     const diaInduccionEvent =
     coordinator?.schedule && coordinator?.schedule?.find((event) => event.day === "Día de Inducción");
   
   const dia1Event =
   coordinator?.schedule && coordinator?.schedule?.find((event) => event.day === "Día 1");


   const datesBetween = getDatesBetween(
    diaInduccionEvent?.date,
    dia1Event?.date
  );
  
  setAcceptData(datesBetween);
  };
  function getDatesBetween(startDate, endDate) {

  
    const dates = [];
  
    // Validaciones de entrada
    if (
      startDate === undefined ||
      startDate.length === 0 ||
      endDate === undefined ||
      endDate.length === 0
    ) {
      return dates;
    }

    const months = {
      ene: 0,
      feb: 1,
      mar: 2,
      abr: 3,
      may: 4,
      jun: 5,
      jul: 6,
      ago: 7,
      sep: 8,
      oct: 9,
      nov: 10,
      dic: 11,
    };
  
    const [startDay, startMonth, startYear] = startDate.split(" ");
    const [endDay, endMonth, endYear] = endDate.split(" ");
  
  
    const start = new Date(
      parseInt(startYear),
      months[startMonth.toLowerCase()],
      parseInt(startDay)
    );
    const end = new Date(
      parseInt(endYear),
      months[endMonth.toLowerCase()],
      parseInt(endDay)
    );
  

    let currentDate = new Date(start);
    currentDate.setDate(currentDate.getDate() + 1); 
    while (currentDate < end) {
      const day = ("0" + currentDate.getDate()).slice(-2); // Formato DD
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Formato MM
      const year = currentDate.getFullYear(); // Formato YYYY
      dates.push(`${day}/${month}/${year}`);
      currentDate.setDate(currentDate.getDate() + 1); // Incrementar día
    }
  
    return dates;
  }
  

  

  
 
  return (
    <>
      <span className="title">
        <b>¡Nuevo(a) Director(a)!</b>{" "}
      </span>
      <Paragraph size="s" align="center" className="host-box-paragraph">
        Estos son los cronogramas de tus Nuevos(as) Coordinadores(as). El día de
        Inducción será mañana y el Día de Alineamiento será un día antes del
        EPA.
      </Paragraph>
      <div className="event-container">
        {captainAndHosts?.map(
          (newDirector) =>
            newDirector?._id === user?._id &&
            newDirector?.children?.map((coordinator) => (
              <div key={coordinator._id}>
                <div className="card-coordinator-alignment-day host-box ">
                  <span className="coordinator-name">
                    {circle.coordinator} {toLowercaseAndCapitalize(coordinator.name)}{" "}
                  </span>
                </div>
                <>
                  {coordinator?.schedule?.map((event, index) => (
                    <>
                      <div className="event">
                        <span className="event-title">{event.day}</span>
                        {event.day === "Día de Reglas" ? (
                          <>
                            <span
                              className="event-date"
                              onClick={() => {
                                handleDateSelection(coordinator,event.date);
                                setModalSchedule(true);
                              }}
                            >
                              {event.date} <img src={pen} alt="pen" />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="event-date">{event.date}</span>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </>
              </div>
            ))
        )}
      </div>
    </>
  );
};
