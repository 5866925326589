import classNames from "classnames";

export const Grid = ({ children, columns, className }) => {
    const gridClasses = classNames('grid', {
        [`grid-columns--${columns}`]: columns,
        [`${className}`]: className
    });

    return (
        <div className={gridClasses}>
            {children}
        </div>
    );
};