import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import TextField from "../../atoms/text-field";
import Button from "../../atoms/button";
import MoneyHand from "../../../resources/images/money-hand.png";
import CurrencyImage from "../../../resources/images/currency-image.png";
import WarningIcon from "../../../resources/images/warning.png";
import ACDCInductionLogo from "../../../resources/images/acdc-induction-logo.png";
import ScreenTouchHand from "../../../resources/images/screen-touch-hand.png";
import Book from "../../../resources/images/book.png";
import SyncCoordinator from "../../../resources/images/sync-coordinator.png";
import ClapHand from "../../../resources/images/clap-hand-coordinator.png";
import Warning from "../../../resources/images/warning.png";
import GoodHand from "../../../resources/images/good-hand.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
import InfoIcon from "../../../resources/images/Info.png";

import CalendarIcon from "../../../resources/images/calendar-icon.png";
import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import { ReferenceBox } from "../../molecules/reference-box";
import GiftSwitch from "../../molecules/gift-switch";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import { GridGiftSwitch } from "../../molecules/grid-gift-switch";
import MenuUserInfo from "../../molecules/menu-user-info";
import { CardCopyKey } from "../../molecules/card-copy-key";
import { CounterBadges } from "../../molecules/counter-badges";
import { TextFieldList } from "../../molecules/text-field-list";

const CoordinadorInductionDay = ({ finishChallenge, step, setStep }) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);
  const [income, setIncome] = useState("");
  const [dailyHours, setDailyHours] = useState("");
  const [daysOfWeek, setDaysOfWeek] = useState("");
  const [weeksOfMonth, setWeeksOfMonth] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [projection, setProjection] = useState({});
  const [scheduleInformation, setScheduleInformation] = useState({});
  const [calculateHourIncomes, setCalculateHourIncomes] = useState(false);
  const [calculateMonthlyIncomes, setCalculateMonthlyIncomes] = useState(false);
  const [acdcProjection, setAcdcProjection] = useState({});
  const [calculateModal, setCalculateModal] = useState(false);
  const [calculateModalMonthly, setCalculateModalMonthly] = useState(false);

  const handleButtonDisabled = () => {
    if (
      income.length > 0 &&
      dailyHours.length > 0 &&
      daysOfWeek.length > 0 &&
      weeksOfMonth.length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const formatNumber = (value) => {
    const newValue = typeof value === "number" ? Math.trunc(value) : value;
    const n = newValue?.toString().replace(/\D/g, ""); // Elimina todo lo que no sea un dígito
    return n?.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Inserta comilla simple para los millones
  };

  const saveProjections = () => {
    const data = {
      monthlyIncome: parseInt(income.split(".").join("")),
      hoursWorkedPerDay: parseInt(dailyHours),
      daysWorkedPerWeek: parseInt(daysOfWeek),
      weeksWorkedPerMonth: parseInt(weeksOfMonth),
    };

    axios
      .put(`${API.SAVE_INCOMES_INFO.url}/${user?._id}`, data)
      .then((response) => {
        if (response.status === 200) {
          setProjection({ ...projection, ...response.data.data });
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleButtonDisabled();
  }, [income, dailyHours, daysOfWeek, weeksOfMonth]);

  useEffect(() => {
    axios
      .get(`${API.GET_PROJECTION.url}?action=GET_PROJECTION`)
      .then((response) => {
        if (response.status === 200) {
          setAcdcProjection(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${API.GET_SCHEDULE_INFORMATION.url}/${user?._id}?action=GET_SCHEDULE_INFORMATION`
      )
      .then((response) => {
        if (response.status === 200) {
          setScheduleInformation(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [hostData, setHostData] = useState(null);
  const [hostActive, setHostActive] = useState([]);
  const [hostIndex, setHostIndex] = useState(0);
  const [hostIndexSelected, setHostIndexSelected] = useState(false);
  const [hostIndexCount, setHostIndexCount] = useState(0);
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const putAddToWhatSapp = (idHost, status, e) => {
    let dataUpdate = {
      status: status,
      property: "addedToWhatsappGroup",
    };

    axios
      .put(
        `${API.ADD_TO_WHATSAPP_GROUP.url}/${idHost}?action=update-property-host`,
        dataUpdate
      )
      .then((response) => {
        if (response.status === 200) {
          setHostActive((prev) => {
            if (status) {
              if (!prev.includes(idHost)) {
                return [...prev, idHost];
              }
            } else {
              return prev.filter((host) => host !== idHost);
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [selectedReferenceCount, setSelectedReferenceCount] = useState(0);
  const [selectedReference, setSelectedReference] = useState({});

  const handleChangePersonalReferences = (e, host, index) => {
    const newValue = e.target.value;
    setSelectedReference((prev) => {
      const updatedReferences = [...(prev[host._id] || [])];
      updatedReferences[index] = newValue;
      return {
        ...prev,
        [host._id]: updatedReferences, // Actualiza las referencias del host actual
      };
    });
    setSelectedReferenceCount(selectedReferenceCount + 1);
  };
  const handleSavePersonalReferences = (e) => {
    console.log("selectedReference save");
    console.log(selectedReference);
    Object.entries(selectedReference).forEach(([hostId, array]) => {
      array.forEach((name) => {
        let data = {
          references: [
            {
              name: name,
              email: "string",
              suggested: true,
            },
          ],
        };
        axios
        .put(`${API.SAVE_PERSONAL_REFERENCES.url}/${hostId}/${idChallenge}`, data)
        .then((response) => { })
        .catch((error) => {
          console.log(error);
        });
      });
    });
  };
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={SyncCoordinator}
              alt="sync-coordinator"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph align="center" size="sx">
              Hoy en tu Inducción como Coordinador(a)
              <br />
              tu principal tarea es sincronizar a tus dos
              <br />
              Anfitriones, quienes deben ver el video de <br />
              Inducción a la hora agendada y completar el <br />
              Desafío A.
              <br />
              <br />
              Una vez logrado, podrán avanzar juntos hacia el <br />
              Desafío B, que es el Entrenamiento ABC de la <br />
              Convocatoria.
            </Paragraph>
            <br />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="sx" align="center">
              Confirma si tus dos Anfitriones están
              <br />
              sincronizados contigo en este momento. Ingresa
              <br />
              al grupo de WhatsApp del equipo ACDC y <br />
              verifica que tus dos Anfitriones han enviado la
              <br />
              foto de pantalla de la primera pausa del video
              <br />
              <b> Inducción Anfitrión.</b>
            </Paragraph>
            <Paragraph
              size="sx"
              align="center"
              withBackground={true}
              style={{
                marginTop: "24px",
              }}
            >
              A continuación debes activar al Anfitrión que ya se encuentre
              sincronizado contigo.
            </Paragraph>

            <GridGiftSwitch>
              {hostData &&
                hostData.map((item) => (
                  <div className="gift-switch-host">
                    <GiftSwitch
                      role={item.role}
                      nameRole={"Nuevo(a) Anfitrión(a)"}
                      name={toLowercaseAndCapitalize(item?.name)}
                      range={7}
                      onClick={(e) =>
                        putAddToWhatSapp(
                          item?._id,
                          !hostActive.includes(item?._id),
                          e
                        )
                      }
                    />
                  </div>
                ))}
            </GridGiftSwitch>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              disabled={hostActive.length === 2 ? false : true}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Vas súper!</b>
              <br />
              <br />
              Te queda poco para completar el <b>Desafío A</b>.
            </Paragraph>
            <img
              src={GoodHandCoordinator}
              alt="good-hand-coordinator"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ahora que ya realizaste las actividades, <br />
              continúa con el siguiente ▶️ VIDEO, para <br />
              seguir con tu <b>Inducción</b>.
              <br />
              <br />
              👋🏻 Ya nos vemos.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Ver video
            </Button>
          </Step>
          <Modal
            isOpen={step === 4}
            className="confirmation-register-modal large"
          >
            <div className="confirmation-register-container">
              <div className="light">
                <img src={WarningIcon} alt="Warning" />
              </div>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                Puedes continuar, pero tu desafío se <br />
                completará al 100% cuando tus dos <br />
                Anfitriones hayan dado <b>Iniciar al Desafío A</b> <br />
                de su Día de Inducción.
              </Paragraph>
              <Button size="small" onClick={() => setStep(step + 1)}>
                ¡Vale!
              </Button>
            </div>
          </Modal>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el Desafío A.
            </Paragraph>

            <img
              src={ClapHand}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ahora que ya realizaste las actividades, continúa con el siguiente
              ▶️ VIDEO, para seguir con tu Inducción.
              <br />
              <br />
              👋🏻 Ya nos vemos.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Ver video
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph size="sx" align="center">
              Este Desafío se completa una vez te agendes
              <br />
              como “Aspirante” de tus dos Anfitriones en la <br />
              Plataforma. En este momento habrás
              <br />
              terminado el entrenamiento ABC Convocatoria <br />
              de tus Anfitriones y ya estarán listos para
              <br /> empezar a convocar a sus Validadores.
            </Paragraph>
            <GridGiftSwitch>
              {hostData &&
                hostData.map((item) => (
                  <div className="gift-switch-host">
                    <GiftSwitch
                      role={item.role}
                      nameRole={"Nuevo(a) Anfitrión(a)"}
                      name={toLowercaseAndCapitalize(item?.name)}
                      range={7}
                      items={[
                        {
                          image: CalendarIcon,
                          title: "Prueba",
                          wait: true,
                          width: "24px",
                        },
                      ]}
                      onClick={(e) =>
                        putAddToWhatSapp(
                          item?._id,
                          !hostActive.includes(item?._id),
                          e
                        )
                      }
                    />
                  </div>
                ))}
              <Paragraph
                size="s"
                align="center"
                style={{
                  width: "129px",
                  top: "-10px",
                  opacity: "0.7",
                }}
              >
                Ya te agendaste al Epa.
              </Paragraph>
              <Paragraph
                size="s"
                align="center"
                style={{
                  width: "129px",
                  top: "-10px",
                  opacity: "0.7",
                }}
              >
                Aún no te has agendado al Epa.
              </Paragraph>
            </GridGiftSwitch>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            className="confirmation-register-modal large"
          >
            <div className="confirmation-register-container">
              <img src={InfoIcon} alt="info" />
              <Paragraph align="center" size="s">
                Recuerda revisar tu correo electrónico <br />
                donde recibirás la invitación al EPA como <br />
                parte del Entrenamiento que estás teniendo <br />
                con tus Anfitriones.
              </Paragraph>
              <Button size="small" onClick={() => setStep(step + 1)}>
                ¡Vale!
              </Button>
            </div>
          </Modal>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={GoodHandCoordinator}
              alt="Clap Hand"
              className="good-hand"
              width="226"
              height="226"
            />
            <CardCopyKey
              phrase="Desafío C Coordinador"
              nameChallenge="Desafío C Coordinador"
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph size="sx" align="center">
              <b>Actividad Recomendación Plan B</b>
              <br />
              <br />
              Para completar tu Meta 1, que consiste en asegurar que tus 2
              Anfitriones logren agendar a 6 Aspirantes cada uno, hemos
              habilitado el Plan B que aplicamos cuando los Anfitriones no han
              logrado completar sus 6 agendados después de convocar a todos sus
              validadores.
              <br />
              <br />
              El Plan B consiste en la posibilidad de volver a aplicar el ABC
              Filtro, lo que permitirá a tus Anfitriones tener nuevos
              validadores en la Plataforma hoy mismo.
              <br />
              <br />
              Estos nuevos validadores podrán ser convocados y agendados hasta
              el Día de Reglas del Juego. Para esto, tú como Coordinador(a), le
              recomendarás a cada Anfitrión una persona por cada cupo que les
              falte para completar los 6 agendados que requieren la{" "}
              <b>Meta 1.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            {hostData &&
              hostData[hostIndex] &&
              hostIndexSelected === false &&
              (() => {
                const host = hostData[hostIndex];
                const candidatesScheduled = host.candidates?.length || 0;
                const suggestedCandidates = Math.max(
                  6 - candidatesScheduled,
                  0
                );

                return (
                  <div>
                    <MenuUserInfo
                      name={toLowercaseAndCapitalize(host.name)}
                      role="host"
                      nameRole="Anfitrión"
                      range={candidatesScheduled}
                      style={{ marginTop: "24px" }}
                    />
                    <Paragraph
                      size="s"
                      align="center"
                      style={{ marginTop: "34px" }}
                    >
                      {`Avance Meta 1: ${candidatesScheduled}/6 Agendados`}
                    </Paragraph>
                    <CounterBadges
                      items={Array(6)
                        .fill(0)
                        .map((_, index) => ({
                          isActive: index < candidatesScheduled,
                        }))}
                    />

                    <Paragraph
                      size="s"
                      align="center"
                      withBackground={true}
                      className="paragraph-induction"
                      style={{ marginTop: "24px" }}
                    >
                      Recomiéndales personas que tanto tú como tu Anfitrión(a)
                      conozcan y que creas tienen buen perfil para ser parte de
                      nuestra comunidad épica.
                      {selectedReference[host?._id]?.length}
                    </Paragraph>
                    <Button
                      onClick={() => {
                        setHostIndexSelected(true);
                        setHostIndexCount(hostIndexCount + 1);
                        setSelectedReference({});
                      }}
                      styles={{
                        position: "relative",
                        marginTop: "75px",
                      }}
                    >
                      Continuar
                    </Button>
                  </div>
                );
              })()}
            {hostData &&
              hostData[hostIndex] &&
              hostIndexSelected === true &&
              (() => {
                const host = hostData[hostIndex];
                const candidatesScheduled = host.candidates?.length || 0;
                const suggestedCandidates = Math.max(
                  6 - candidatesScheduled,
                  0
                );

                return (
                  <div>
                    <Paragraph size="sx" align="center">
                      {`Se habilitaron ${suggestedCandidates} Validadores recomendados para ${suggestedCandidates} cupos restantes del(la) Anfitrión(a):`}
                    </Paragraph>
                    <TextFieldList>
                      {Array(suggestedCandidates)
                        .fill(null)
                        .map((_, index) => (
                          <TextField
                            key={`host-${host._id}-field-${index}`}
                            placeholder="Escribe el nombre aquí"
                            value={selectedReference[host._id]?.[index] || ""}
                            onChange={(e) =>
                              handleChangePersonalReferences(e, host, index)
                            }
                            rightIcon={{
                              size: 24,
                              color: "#0063DD",
                            }}
                          />
                        ))}
                    </TextFieldList>
                    <Paragraph
                      size="s"
                      align="center"
                      withBackground={true}
                      className="paragraph-induction"
                      style={{ marginTop: "24px" }}
                    >
                      Recomiéndales personas que tanto tú como tu Anfitrión(a)
                      conozcan y que creas tienen buen perfil para ser parte de
                      nuestra comunidad épica.
                    </Paragraph>
                    {hostIndexCount === 3 ? (
                      <>
                        <Button
                          onClick={() => {
                            setHostIndexSelected(false);
                            setHostIndex(0);
                            setHostIndexCount(0);
                            setStep(step + 1);
                            setSelectedReference({});
                            handleSavePersonalReferences();
                          }}
                          styles={{
                            position: "relative",
                            marginTop: "75px",
                          }}
                          disabled={
                            host?._id &&
                            suggestedCandidates ===
                              (selectedReference[host._id]?.length || 0)
                              ? false
                              : true
                          }
                        >
                          Continuar
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setHostIndexSelected(false);
                            setHostIndex(1);
                            setHostIndexCount(hostIndexCount + 1);
                            handleSavePersonalReferences();
                          }}
                          styles={{
                            position: "relative",
                            marginTop: "75px",
                          }}
                          disabled={
                            host?._id &&
                            suggestedCandidates ===
                              (selectedReference[host._id]?.length || 0)
                              ? false
                              : true
                          }
                        >
                          Continuar
                        </Button>
                      </>
                    )}
                  </div>
                );
              })()}
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Vas super!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>

            <img
              src={GoodHandCoordinator}
              alt="Clap Hand"
              className="good-hand"
              width="226"
              height="226"
            />
            <CardCopyKey
              phrase="Desafío C Coordinador"
              nameChallenge="Desafío C Coordinador"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "0px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinadorInductionDay;
