import { Fragment, useContext, useEffect, useState } from "react";
import { getSchedule } from "../../../middleware/services/common";
import Button from "../../atoms/button";
import CarouselSlide from "../../atoms/carousel-slide";
import PortalBridge from "../../atoms/portal-bridge";
import CarouselModal from "../../molecules/carousel-modal";
import InfoBox from "../../molecules/info-box";
import { UserContext } from "../../../middleware/providers/user-context";
import { formatDateLong } from "../../../middleware/utils/formatDate";
import { indexes } from "../../../middleware/utils/constants";
import Paragraph from "../../atoms/paragraph";

const WelcomeModal = ({ role, roleName, level, isOpen,day, onClose }) => {
  const { user } = useContext(UserContext);
  const [schedule, setSchedule] = useState([]);
  const titleText = `¡Bienvenido(a) a la Plataforma <br/> ACDC!`;
  const formattedTitle = titleText.split("<br/>").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));

  const paragraphText = `Para convertirte en un verdadero líder, hoy <br/> deberás asegurarte de que tus 2 Anfitriones<br/> logren un alto desempeño.<br/>
    ¿Cómo? Completando su Meta 1. 
    <br/>
    ¡Así como tú lo lograste!
    <br/>
    <br/>
    Hoy conocerás los pensamientos y las <br/> acciones fundamentales para completar la <br/>
     Meta 2 a partir de la lectura del Arte y la <br/> Ciencia del Liderazgo.<br/>
    ¡Comencemos!`;

  const formattedParagraph = paragraphText.split("<br/>").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));

  useEffect(() => {
    if (user?._id) {
      fetchData(user?._id);
    }
  }, [user]);
  const fetchData = async (userId) => {
    const schedule = await getSchedule(userId);
    setSchedule(schedule);
  };

  return (

    <CarouselModal isOpen={isOpen}>
      <CarouselSlide>
        <InfoBox
          role={role}
          roleName={roleName}
          level={level}
          day={<>Día de Reglas 🎮</>}
          title={formattedTitle}
          paragraph={formattedParagraph}
        ></InfoBox>
      </CarouselSlide>
      <CarouselSlide>
      <div className="info-box-badge-target-icon"></div>
        <Paragraph align="center" size="sx">
          Te encuentras cumpliendo tu <br /> <b>Meta 1.</b>
          <br />
          <br />
          Asegurar que cada uno de tus dos <br />
          Anfitriones, logre agendar a seis Aspirantes <br /> para el EPA.
          <br />
          <br />
          <br />
          Recuerda que siempre puedes revisar el estado de tus metas ,desde{" "}
          <b>Mi Desempeño.</b>
        </Paragraph>
      </CarouselSlide>
      <CarouselSlide>
        <div className="info-section-bridge">
          <h3>Día de Reglas</h3>
          <PortalBridge id="day-rules" role={role}>
            {schedule?.map((day, index) => (
              <span className={indexes[index]}>{formatDateLong(day.date)}</span>
            ))}
          </PortalBridge>
          <p>
            Deslizando el selector hacia Día de Reglas en el Cronograma que
            verás en la pantalla, encontrarás, paso a paso, las actividades que
            debes realizar.
          </p>
         
        </div>
      </CarouselSlide>
      <CarouselSlide>
      <div className="info-box-megaphone-icon"></div>
        <Paragraph align="center" size="sx">
        😉<b>Recordatorio</b> 👇🏼
          <br />
          <br />
          No hay respuestas correctas o incorrectas.

          <br />
          <br />
          TWOBOT 🤖 y tu Director(a) 🟡 estarán contigo, hasta el final.
        </Paragraph>
        <br />
        <br />
        <Button size="small" onClick={onClose}>
            ¡Vale!
          </Button>
      </CarouselSlide>
    </CarouselModal>
  );
};

export default WelcomeModal;
