import Paragraph from '../../../../atoms/paragraph';
import { ROLES } from '../../../../../middleware/utils/constants';

const circle = {
  host: '⚪',
  'new host': '⚪',
  coordinator: '🔴',
  'new coordinator': '🔴',
  director: '🟡',
  captain: '🔵',
};
const ActivitySelectedCoordinatorRoomA = ({ applicantsAndHosts }) => {


  return (
    <>
      <span className="title"><b>Actividad Selección</b></span>

      <Paragraph size="s" align="center" className="p-room-p-1">
        Coordinador(a), estas son las respuestas de tus Anfitriones a la siguiente pregunta:
      </Paragraph>
      <Paragraph size="s" align="center" className="p-room-p-1">

        Si la decisión de quiénes son tus dos dependiera solo de ti,¿a quiénes seleccionarías?.
      </Paragraph>

      {applicantsAndHosts?.map((generation) => <div className="generation-map">
        <Paragraph size="s" align="center" >
        <br/>
          <div  >
            <div className={`info `} >
              <span className="role"> {ROLES[generation.role]?.circle} </span>
              <span className="name"><b>{generation.name}:</b></span>
            </div>
           
          </div>
        </Paragraph>
        <div className="epa-requirements__status">
          {
            generation.candidates?.map((candidate) => candidate.role == "candidate" && (
              <>
                <span className={`medal-switch `}>
                  <span className="medal-switch__info__name">{candidate.name}</span>
                </span>
              
              </>
            ))
          }
        </div>
      </div>)}
    </>
  )
}

export default ActivitySelectedCoordinatorRoomA;