import React, { useContext, useEffect, useState } from 'react'
import Paragraph from '../../../../atoms/paragraph'
import { ROLES } from '../../../../../middleware/utils/constants'
import Checkbox from '../../../../atoms/checkbox'
import Button from '../../../../atoms/button'
import { getListOfHostWithTheirSelectApplicants } from '../../../../../middleware/services/epaCoordinatorServices'
import { UserContext } from '../../../../../middleware/providers/user-context'

const ActivitySelectedPDLRoomA = ({setNumPager,setStep,step, applicantsAndHosts }) => {
 
  const{user}=useContext(UserContext)
  const [selectedCandidate, setSelectedCandidate] = useState([]);

  useEffect(()=>{
     getListOfHostWithTheirSelectApplicants(user?._id).then(response => {
      setSelectedCandidate(response)
    })
    setNumPager(0)
 
  },[])

  return (
    <>
      <span className="title"><b>Actividad Selección</b></span>
<br/>
      <Paragraph size="s" align="center" className="p-room-p-1">
        Coordinador(a), estas son las respuestas de tus Anfitriones a la siguiente pregunta ¡Revísalas!
<br/>
<br/>
      </Paragraph>
      <Paragraph size="s" align="center" className="p-room-p-1">


        De los PDLs de tus Aspirantes, ¿cuál está más conectado con tu PDL?
      </Paragraph>
      {selectedCandidate?.map((generation) =>   <div className="generation-map">
        <Paragraph size="s" align="center" >
          <br/>
          <div  >
            <div className={`info `} >
              <span className="role"> {ROLES[generation.role]?.circle} </span>
              <span className="name"><b>{generation.name}:</b></span>
            </div>
          </div>
        </Paragraph>
        <div className="epa-requirements__status">
          {generation.candidates?.map((candidate, index) =>candidate.role == "candidate" && (
            <span className={`medal-switch `}>
              <Checkbox
                className="medal-switch__info__name"

                name="hold"
                label={<>
                  {candidate.name}
                  <br />
                  {ROLES[candidate.role || 'candidate'].circle}  {ROLES[candidate.role || 'candidate'].roleName}
                </>}
                onChange={(e) => setSelectedCandidate(e.target.checked)}
                disabled={true}
                checked={candidate?.pdlConnection?candidate?.pdlConnection:false}
              />
            </span>
          ))}
          
        </div>
      </div>)}    
      <Button onClick={() =>{ setStep(step + 1)
setNumPager(0)

      }} className="margin-top">Continuar</Button>
      </>
  )
}
export default ActivitySelectedPDLRoomA