import axios from "axios";
import { API } from "../utils/constants";

const assignMedal = async (medalName, value, idUser, idChallenges) => {
    const medalData = {
        typeMedals: medalName,
        medal: value === 'Si'
    };

    const eventData = {
        id: 'all',
        message: 'refresh',
        action: 'GENERIC_EVENT'
    };

    try {
        const responseAssignMedal = await axios.put(`${API.REGISTER_MEDAL.url}/${idUser}/${idChallenges}`, medalData);
        if (responseAssignMedal.status === 200) {
            console.log('Medal assigned successfully');
            const medalEventData = {
                ...medalData,
                medal: true,
                action: 'EVENT_MEDAL_ALLOCATION'
            };
            await pushMedalEvent(medalEventData, idUser);
        }
    } catch (error) {
        console.log(error);
    }

    await pushGenericEvent(eventData);
};

const getSchedule = async (idUser) => {
    let schedule
    try {
        const response = await axios.get(`${API.GET_SCHEDULE_BY_USER.url}/${idUser}?action=GET_SCHEDULE_BY_USER`);
        console.log("response")
        console.log(response)
        if (response.status === 200) {
            schedule = response.data?.data?.schedule;
        }
    } catch (error) {
        console.log(error);
    }
    return schedule;
};

const getInformation = async (idUser) => {
    let information;
    try {
        const response = await axios.get(`${API.GET_INFORMATION.url}/${idUser}?action=GET_INFORMATION`);
        if (response.status === 200) {
            information = response.data.data;
        }
    } catch (error) {
        console.log(error);
    }

    return information;
};

const getHostWinners = async (idUser, role) => {
    try {
        let hostWinners = [];
        const response = await axios.get(`${API.GET_TIME_MANAGEMENT_HOST_WINNERS.url}/${idUser}/${role}?action=GET_TIME_MANAGEMENT_HOST_WINNERS`);
        if (response.status === 200) {
            hostWinners = response.data.data;
        }
        return hostWinners;
    } catch (error) {
        console.log(error);
    }
};

const pushMedalEvent = async (eventData, idUser) => {
    try {
        const response = await axios.post(`${API.EVENT_MEDAL_ALLOCATION.url}/${idUser}`, eventData);
        if (response.status === 201) {
            console.log('Event created successfully');
        }
    } catch (error) {
        console.log(error);
    }
};

const pushGenericEvent = async (eventData) => {
    try {
        const response = await axios.post(`${API.GENERIC_EVENT.url}`, eventData);
        if (response.status === 201) {
            console.log('Event created successfully');
        }
    } catch (error) {
        console.log(error);
    }
};

const saveResponseChallengeEpaRequirements = (data, userId) => {

    axios.put(`${API.EPA_PARTICIPATION_REQUIREMENTS.url}/${userId}`, data).then(async (response) => {
        if (response.status === 200) {
            await pushGenericEvent({
                id: 'all',
                message: 'refresh',
                action: 'GENERIC_EVENT'
            });
        }
    }).catch((error) => {
        console.log(error);
    });
};

const getProgressPoint = async (userId, day, role) => {

    const params = day ? { day } : undefined;

    try {
        const response = await axios.get(`${API.PROGRESS_POINT.url}${userId ? `/${userId}` : ''}?action=GET_PROGRESS_POINT`, {
            params
        });
        if (response.status === 200) {
            return response.data.data.filter((challenge) => challenge.role === role);
        }
    } catch (error) {
        console.log(error);
    }
};

const getChallengesWithMedals = async (userId, day) => {
    const data = {
        params: {
            day,
        }
    };

    try {
        const response = await axios.get(`${API.CHALLENGES_WITH_MEDALS_BE_ASSIGNED.url}/${userId}?action=CHALLENGES_WITH_MEDALS_BE_ASSIGNED`, data);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

const saveStepOfAChallenge = async (userId, challengeId, step) => {

    try {
        const response = await axios.put(`${API.SAVE_STEP_OF_A_CHALLENGE.url}/${userId}/${challengeId}/${step}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

const getListCandidateKeys = async (userId) => {
    try {
        const response = await axios.get(`${API.LIST_CANDIDATES_KEYS.url}/${userId}?action=LIST_CANDIDATES_KEYS`);
        if (response.status === 200) {
            return response.data.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export { 
    assignMedal, 
    getSchedule, 
    getInformation,
    getHostWinners, 
    getProgressPoint,
    getListCandidateKeys,
    getChallengesWithMedals,
    saveStepOfAChallenge,
    saveResponseChallengeEpaRequirements 
};